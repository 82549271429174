import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Cpostal_response, Respuesta_WS_response, VersionANA_response, VersionGNP_response } from 'src/model/Catalogos/Cotizacion';
import { Descripcion_modelo_response, Marca_modelo_response, Modelos_modelo_response, Version_modelo_response } from 'src/model/Catalogos/Ramos';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as html2pdf from 'html2pdf.js';
import { AuthIdentity } from 'src/app/guards/AuthIdentity';
import { MenuService } from 'src/app/menu.service';
import { Respuesta_WS_Emision_response } from 'src/model/Catalogos/Emision';
import { Lista_aseguradoras_modelo_response } from 'src/model/Catalogos/Generales';

declare const RFCFiltraAcentos: any;
declare const RFCFiltraNombres: any;
declare const RFCApellidoCorto: any;
declare const RFCArmalo: any;
declare const RFCUnApellido: any;
declare const homonimia: any;
declare const RFCQuitaProhibidas: any;
declare const RFCDigitoVerificador: any;


@Component({
  selector: 'app-autos',
  templateUrl: './autos.component.html',
  styleUrls: ['./autos.component.css'],
  providers: [ServiceGenerico]
})
export class AutosComponent implements OnInit {
  @ViewChild("contentCotPDF", { static: false }) ModalCuponPDFH: TemplateRef<any>;
  modalCuponPDFH: NgbModalRef;

  modalrefMsg: NgbModalRef;
  public operacionRespuesta: RespuestaGenerica;
  private modelo_configuracion: ServiciosRutas;
  listaMarca: Marca_modelo_response[];
  listaAnios: Modelos_modelo_response[];
  modelo: number;
  descripcion: number;
  marca: number;
  listaDescripcion: Descripcion_modelo_response[];
  listaVersion: Version_modelo_response[];
  formGroupCotizacion: FormGroup;
  formGroupEmision: FormGroup;
  // formGroupEmisionRespuesta: FormGroup;
  formGroupReCotizacion: FormGroup;
  etapa: number = 1;
  respuestaQualitas: Respuesta_WS_response;
  respuestaANA: Respuesta_WS_response;
  respuestaGNP: Respuesta_WS_response;
  qualitasDisponible: boolean;
  anaDisponible: boolean;
  gnpDisponible: boolean;
  listaColonias: Cpostal_response[];
  paquete: number = 1;
  qualitas: number = 0;
  ana: number = 0;
  gnp: number = 0;
  primaTotalANA: number = 0;
  primaTotalQualitas: number = 0;
  primaTotalGNP: number = 0;
  formGroupReCotizacionQualitas: FormGroup;
  formGroupReCotizacionANA: FormGroup;
  formGroupReCotizacionGNP: FormGroup;
  nombreUsuario: string;
  idUsuario: any;
  todayFormatted: string;
  tomorrowFormatted: string;
  listaAseguradoras: Lista_aseguradoras_modelo_response[];

  opcionMenu: number;
  claveAgente: string
  titularEsConductoH: boolean = true;
  beneficiarioPreferente: boolean = false;
  VersionesQUA: string;
  VersionesANA: VersionANA_response = new VersionANA_response();
  VersionesGNP: VersionGNP_response = new VersionGNP_response();
  hasErrorAna: boolean;
  hasErrorQua: boolean;
  hasErrorGNP: boolean;
  CiaLoaderQ: boolean = false;
  CiaLoaderA: boolean = false;
  CiaLoaderG: boolean = false;
  respuestaEmisionWS: Respuesta_WS_Emision_response;
  router: any;
  esValorFactura: boolean = false;
  esValorFacturaA: boolean = false;
  esValorFacturaG: boolean = false;
  constructor(
    private services: ServiceGenerico,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private menuService: MenuService
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();

    var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
    this.nombreUsuario = usuarioActual.Nombre + ' ' + usuarioActual.APaterno + ' ' + usuarioActual.AMaterno;
    this.claveAgente = usuarioActual.Clave_asesor;

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

   this.todayFormatted = today.toISOString().split('T')[0]; // Formato: AAAA-MM-DD
  this.tomorrowFormatted = tomorrow.toISOString().split('T')[0]; // Formato: AAAA-MM-DD
  }

  ngOnInit() {
    this.obtenerMarcas(1);
    this.inicializarFormCotiza();
    this.inicializarFormReCotiza();
    this.inicializaFormEmite();
    this.inicializarFormReCotizaQualitas();
    this.inicializarFormReCotizaANA();
    this.inicializarFormReCotizaGNP();
    this.getAseguradorasDisponibles();
    // this.inicializaFormEmiteRespuesta();
    this.menuService.opcionMenu$.subscribe((opcion) => {
      this.opcionMenu = opcion;
    });
  }

  //#region select marca, modelo descripción
  obtenerMarcas(opcion) {
    this.operacionRespuesta.EstaEjecutando = true;

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetMarcas?p_modelo=" + opcion)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaMarca = tempdate.response as Marca_modelo_response[];
            this.listaDescripcion = [];
            this.listaVersion = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerModelos(event: any) {
    this.operacionRespuesta.EstaEjecutando = true;
    const _Marca = event.target.value;

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetModelo?p_marca=" + _Marca)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaAnios = tempdate.response as Modelos_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerDescripcion(event: any) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetDescripcion?p_marca=" + this.formGroupCotizacion.value.p_Marca + "&p_modelo=" + this.formGroupCotizacion.value.p_Modelo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaDescripcion = tempdate.response as Descripcion_modelo_response[];
            this.listaVersion = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerVersion(event: any) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.listaVersion = [];
    this.services
      .HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Generales/GetVersion?p_marca=" + this.formGroupCotizacion.value.p_Marca + "&p_modelo=" + this.formGroupCotizacion.value.p_Modelo + "&p_descripcion=" + this.formGroupCotizacion.value.p_Descripcion)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaVersion = tempdate.response as Version_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  //#endregion

  //#region Formulario
  inicializarFormCotiza() {
if (!this.claveAgente) {
 this.claveAgente = ''
}

    this.formGroupCotizacion = this.fb.group({
      p_numeroEmpleado: [""],
      p_claveAsesor: [this.claveAgente, [Validators.required, this.validateClaveAsesor]],
      p_NombreProspecto: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\u00C0-\u017F ]*$/)]],
      p_ApellidoPProspecto: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\u00C0-\u017F ]*$/)]],
      p_ApellidoMProspecto: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\u00C0-\u017F ]*$/)]],
      p_Correo: ["", Validators.compose([Validators.required, Validators.email])],
      p_Celular: ["", [Validators.required, this.validateCelular]],
      p_CPostal: ["", [Validators.required, this.validateCPostal]],
      p_FNacimiento: ["", [Validators.required, this.ageRangeValidator(18, 99)]],
      p_IDGenero: [0, [Validators.required, this.validateGenero]],
      p_Modelo: [""],
      p_Marca: [""],
      p_Descripcion: [""],
      p_Version: [""],
      p_FormaPago: ["1"],
      p_Paquete: [1],
      p_Valor: [1],
      p_SumaAseguradaVF: [0],
      p_enviarCRM: [1],
      p_dMateriales: [5],
      p_roboTotal: [10],
      p_RC: [3000000],
      p_GM: [200000],
      p_QUALITAS: [1],
      p_ANA: [1],
      p_GNP: [1],
      p_tipoVehiculo: [0],
      p_dMaterialesQ: [5],
      p_roboTotalQ: [10],
      p_dMaterialesA: [5],
      p_roboTotalA: [10],
      p_dMaterialesG: [5],
      p_roboTotalG: [10],
      p_ValorQ: [1],
      p_SumaAseguradaVFQ: [0],
      p_ValorA: [1],
      p_SumaAseguradaVFA: [0],
      p_ValorG: [1],
      p_SumaAseguradaVFG: [0],
      p_RCQ: [3000000],
      p_GMQ: [200000],
      p_RCA: [3000000],
      p_GMA: [200000],
      p_RCG: [3000000],
      p_GMG: [200000],
      p_FNacimientoCH: ["", [this.ageRangeValidator(18, 99)]],
      p_IDGeneroCH: [0],
      p_CPostalCH: [""],
      p_VersionQUA: [""],
      p_VersionANA: [""],
      p_VersionGNP: [""],
      p_numCotizacionQ: [""],
      p_numCotizacionA: [""],
      p_numCotizacionG: [""],
      p_IDCotizacion: [0],
    });
  }

  validateGenero(control) {
    if (control.value === 0) {
      return { 'invalidValue': true };
    }
    return null;
  }

  validateCelular(control) {
    const value = control.value;

    if (value === "") {
      return null;
    }
    else if (value.length === 10 && /^\d+$/.test(value)) {
      return null;
    } else {
      return { 'invalidCelular': true };
    }
  }

  validateClaveAsesor(control) {
    const value = control.value.trim(); // Elimina espacios en blanco al inicio y al final

    if (value === '') {
      return { 'required': true };
    } else {
      return null;
    }
  }


  validateCPostal(control) {
    const value = control.value;

    if (value === "") {
      return null;
    }
    else if (value.length === 5 && /^\d+$/.test(value)) {
      return null;
    } else {
      return { 'invalidCpostal': true };
    }
  }


  inicializarFormReCotiza() {
    this.formGroupReCotizacion = this.fb.group({
      p_FormaPago: ["1"],
      p_Paquete: [1],
    });
  }

  inicializarFormReCotizaQualitas() {
    this.formGroupReCotizacionQualitas = this.fb.group({
      p_dMaterialesQ: [5],
      p_roboTotalQ: [10],
      p_RCQ: [3000000],
      p_GMQ: [200000],
      p_ValorQ: [1],
      p_SumaAseguradaVFQ: [0],
    });
  }

  inicializarFormReCotizaANA() {
    this.formGroupReCotizacionANA = this.fb.group({
      p_dMaterialesA: [5],
      p_roboTotalA: [10],
      p_RCA: [3000000],
      p_GMA: [200000],
      p_VersionANA: ["0"],
      p_ValorA: [1],
      p_SumaAseguradaVFA: [0],
 });
  }

  inicializarFormReCotizaGNP() {
    this.formGroupReCotizacionGNP = this.fb.group({
      p_dMaterialesG: [5],
      p_roboTotalG: [10],
      p_RCG: [3000000],
      p_GMG: [200000],
      p_VersionGNP: ["0"],
      p_ValorG: [1],
      p_SumaAseguradaVFG: [0],
    });
  }

  inicializaFormEmite() {
    this.formGroupEmision = this.fb.group({
      p_numeroEmpleado: [""],
      p_claveAsesor: [this.claveAgente],
      p_NombreProspecto: ["", Validators.required],
      p_ApellidoPProspecto: ["", Validators.required],
      p_ApellidoMProspecto: ["", Validators.required],
      p_Correo: ["", Validators.compose([Validators.required, Validators.email])],
      p_Celular: [""],
      p_CPostal: ["", Validators.required],
      p_FNacimiento: ["", [Validators.required, this.ageRangeValidator(18, 99)]],
      p_IDGenero: [0],
      p_Modelo: [""],
      p_Marca: [""],
      p_Descripcion: [""],
      p_Version: [""],
      p_FormaPago: ["1"],
      p_Paquete: [1],
      p_Valor: [1],
      p_SumaAseguradaVF: [0],
      p_enviarCRM: [1],
      p_dMateriales: [5],
      p_roboTotal: [10],
      p_RC: [3000000],
      p_GM: [200000],
      p_QUALITAS: [1],
      p_ANA: [1],
      p_GNP: [1],
      p_tipoVehiculo: [0],
      p_dMaterialesQ: [5],
      p_roboTotalQ: [10],
      p_dMaterialesA: [5],
      p_roboTotalA: [10],
      p_dMaterialesG: [5],
      p_roboTotalG: [10],
      p_RCQ: [3000000],
      p_GMQ: [200000],
      p_RCA: [3000000],
      p_GMA: [200000],
      p_RCG: [3000000],
      p_GMG: [200000],
      p_ValorQ: [1],
      p_SumaAseguradaVFQ: [0],
      p_ValorA: [1],
      p_SumaAseguradaVFA: [0],
      p_ValorG: [1],
      p_SumaAseguradaVFG: [0],
      p_FNacimientoCH: ["", [this.ageRangeValidator(18, 99)]],
      p_IDGeneroCH: [0],
      p_CPostalCH: [""],
      p_VersionQUA: [""],
      p_VersionANA: [""],
      p_VersionGNP: [""],
      p_NumeroSerie: ["", [Validators.required, this.customVinValidator]],
      p_Motor: ["", Validators.required],
      p_Placa: ["", Validators.required],
      p_RFC: ["", Validators.required],
      p_Telefono: ["", Validators.required],
      p_Calle: ["", Validators.required],
      p_Next: ["", Validators.required],
      p_Nint: [""],
      p_Colonia: [""],
      p_Municipio: [""],
      p_Estado: [""],
      p_AseguradoraSeleccionada: [0],
      p_BeneficiarioPreferente: [""],

      p_numCotizacionQ: [""],
      p_numCotizacionA: [""],
      p_numCotizacionG: [""],
      p_CveAmis: [""],

      p_TIdentificacion: ["C3"],
      p_Nidentificacion: [""],
      p_IDCotizacion:[""],
      p_primaTotalG: [""],
      p_ivaG: [""],
      p_primaNetaG: [""]
    });
  }

  customVinValidator(control) {
    const vin = control.value;
    const re = /^[A-HJ-NPR-Z\d]{8}[\dX][A-HJ-NPR-Z\d]{2}\d{6}$/;
    return re.test(vin) ? null : { 'invalidVin': true };

  }
  

  limpiarFormulario() {
    this.formGroupCotizacion.reset({
      p_NombreProspecto: '', p_ApellidoPProspecto: '', p_ApellidoMProspecto: '', p_Correo: '', p_Celular: '', p_CPostal: '',
      p_FNacimiento: '', p_IDGenero: 0, p_Modelo: '', p_Marca: '', p_Descripcion: '', p_Version: '', p_FormaPago: "1", p_Paquete: 1,p_Valor: "1", p_SumaAseguradaVF:0 , p_enviarCRM: 1,
      p_dMateriales: 5, p_roboTotal: 10, p_RC: 3000000, p_GM: 200000, p_FNacimientoCH: '', p_IDGeneroCH: 0, p_CPostalCH: '',
    });

    this.formGroupEmision.reset({
      p_NumeroSerie: '', p_Motor: '', p_Placa: '', p_NombreProspecto: '',
      p_ApellidoPProspecto: '', p_ApellidoMProspecto: '', p_RFC: '', p_IDGenero: 0, p_FNacimiento: '', p_Telefono: '', p_Celular: 1, p_Calle: 1, p_Next: 1,
      p_Nint: '', p_CPostal: '', p_Colonia: '', p_Municipio: '', p_Estado: ''
    });


  }

  updateRCGM() {
    this.formGroupCotizacion.value.p_dMaterialesQ = this.formGroupCotizacion.value.p_dMateriales;
    this.formGroupReCotizacionQualitas.value.p_dMaterialesQ = this.formGroupCotizacion.value.p_dMateriales;
    this.formGroupReCotizacionANA.value.p_dMaterialesA = this.formGroupCotizacion.value.p_dMateriales;
    this.formGroupReCotizacionGNP.value.p_dMaterialesG = this.formGroupCotizacion.value.p_dMateriales;

    this.formGroupCotizacion.value.p_roboTotalQ = this.formGroupCotizacion.value.p_roboTotal;
    this.formGroupReCotizacionQualitas.value.p_roboTotalQ = this.formGroupCotizacion.value.p_roboTotal;
    this.formGroupReCotizacionANA.value.p_roboTotalA = this.formGroupCotizacion.value.p_roboTotal;
    this.formGroupReCotizacionGNP.value.p_roboTotalG = this.formGroupCotizacion.value.p_roboTotal;

    this.formGroupCotizacion.value.p_RCQ = this.formGroupCotizacion.value.p_RC;
    this.formGroupReCotizacionQualitas.value.p_RCQ = this.formGroupCotizacion.value.p_RC;
    this.formGroupReCotizacionANA.value.p_RCA = this.formGroupCotizacion.value.p_RC;
    this.formGroupReCotizacionGNP.value.p_RCG = this.formGroupCotizacion.value.p_RC;

    this.formGroupCotizacion.value.p_GMQ = this.formGroupCotizacion.value.p_GM;
    this.formGroupReCotizacionQualitas.value.p_GMQ = this.formGroupCotizacion.value.p_GM;
    this.formGroupReCotizacionANA.value.p_GMA = this.formGroupCotizacion.value.p_GM;
    this.formGroupReCotizacionGNP.value.p_GMG = this.formGroupCotizacion.value.p_GM;

    this.formGroupCotizacion.patchValue(this.formGroupCotizacion.value);
    this.formGroupReCotizacionQualitas.patchValue(this.formGroupReCotizacionQualitas.value);
    this.formGroupReCotizacionANA.patchValue(this.formGroupReCotizacionANA.value);
    this.formGroupReCotizacionGNP.patchValue(this.formGroupReCotizacionGNP.value);
  }

  ageRangeValidator(minAge: number, maxAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // No error if the field is empty
      }

      const birthDate = new Date(control.value);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();

      if (age < minAge || age > maxAge) {
        return { ageRange: true }; // Return error if age is out of range
      }

      return null; // No error if age is within range
    };
  }
  //#endregion

//#region cotización
async recotizar() {
  this.formGroupCotizacion.get('p_FormaPago').setValue(this.formGroupReCotizacion.value.p_FormaPago);
  this.formGroupCotizacion.get('p_Paquete').setValue(this.formGroupReCotizacion.value.p_Paquete);

  await this.cotizaQualitas();
  await this.cotizaANA();
  await this.cotizaGNP();
}


  async cotizar() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.formGroupCotizacion.get('p_SumaAseguradaVFQ').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVF);
    this.formGroupCotizacion.get('p_SumaAseguradaVFA').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVF);
    this.formGroupCotizacion.get('p_SumaAseguradaVFG').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVF);

      if (this.titularEsConductoH == true) {
        this.formGroupCotizacion.get('p_IDGeneroCH').setValue(this.formGroupCotizacion.value.p_IDGenero);
        this.formGroupCotizacion.get('p_FNacimientoCH').setValue(this.formGroupCotizacion.value.p_FNacimiento);
        this.formGroupCotizacion.get('p_CPostalCH').setValue(this.formGroupCotizacion.value.p_CPostal);
      }

      
      let model = this.formGroupCotizacion.value;

    const _p_enviarCRM = document.querySelector('input[id="p_enviarCRM"]') as HTMLInputElement;
    if (_p_enviarCRM) {
      if (_p_enviarCRM.checked) {
        model.p_enviarCRM = 1;
      } else {
        model.p_enviarCRM = 0;
      }
    }

    for (let i = 0; i < this.listaAseguradoras.length; i++) {
      if (this.listaAseguradoras[i].idAseguradora === 1) {
        this.qualitas = 1;

        const _qualitas = document.querySelector('input[id="p_QUALITAS"]') as HTMLInputElement;
        if (_qualitas) {
          if (_qualitas.checked) {
            this.qualitas = 1;
          } else {
            this.qualitas = 0;
          }
        }
      }

      if (this.listaAseguradoras[i].idAseguradora === 2) {
        this.gnp = 1;

        const _gnp = document.querySelector('input[id="p_GNP"]') as HTMLInputElement;
        if (_gnp) {
          if (_gnp.checked) {
            this.gnp = 1;
          } else {
            this.gnp = 0;
          }
        }
      }

      if (this.listaAseguradoras[i].idAseguradora === 3) {
        this.ana = 1;

        const _ana = document.querySelector('input[id="p_ANA"]') as HTMLInputElement;
        if (_ana) {
          if (_ana.checked) {
            this.ana = 1;
          } else {
            this.ana = 0;
          }
        }
      }

    }


      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/PostCotizacion")
        .subscribe(
          async (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.etapa = 2;
              this.formGroupCotizacion.get('p_IDCotizacion').setValue(tempdate.response[0]._IDCotizacion);
              await this.cotizaQualitas();
              await this.getVersionesANA();
              await this.cotizaANA();
              await this.getVersionesGNP();
              await this.cotizaGNP();
            } else {
              this.etapa = 1;
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            // this.operacionRespuesta.EstaEjecutando = false;
          }
        );

  }
  async cotizaQualitas() {
    if (this.qualitas) {
    this.qualitasDisponible = false;
    // this.operacionRespuesta.EstaEjecutando = true;
    this.CiaLoaderQ = true;
    this.primaTotalQualitas = 0;
    this.formGroupReCotizacionQualitas.get('p_ValorQ').setValue(this.formGroupCotizacion.value.p_ValorQ);
    this.formGroupReCotizacionQualitas.get('p_SumaAseguradaVFQ').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVFQ);
      if (this.formGroupCotizacion.value.p_ValorQ == '5') {
        this.esValorFactura = true;
      } else {
        this.esValorFactura = false;
      }

    let model = this.formGroupCotizacion.value;
    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/CotizaQualitas")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.qualitasDisponible = true;
            this.respuestaQualitas = tempdate.response[0] as Respuesta_WS_response;
            this.primaTotalQualitas = this.respuestaQualitas.primaTotal;
            this.formGroupCotizacion.get('p_numCotizacionQ').setValue(this.respuestaQualitas.numCotizacion);
            this.hasErrorQua = this.respuestaQualitas.hasError;
          } else {
            this.qualitasDisponible = false;
            this.hasErrorQua = false;
        }
          // this.operacionRespuesta.EstaEjecutando = false;
          this.CiaLoaderQ = false;
        },
        async (err) => {
          this.qualitasDisponible = false;
          this.hasErrorQua = true;
          this.CiaLoaderQ = false;
          //  this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
  }
  async cotizaANA() {
    if (this.ana) {
      this.anaDisponible = false;
      this.CiaLoaderA = true;

    // this.operacionRespuesta.EstaEjecutando = true;
    this.primaTotalANA = 0;

    this.formGroupReCotizacionANA.get('p_ValorA').setValue(this.formGroupCotizacion.value.p_ValorA);
    this.formGroupReCotizacionANA.get('p_SumaAseguradaVFA').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVFA);
      if (this.formGroupCotizacion.value.p_ValorA == '5') {
        this.esValorFacturaA = true;
      } else {
        this.esValorFacturaA = false;
      }

    let model = this.formGroupCotizacion.value;
    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/CotizaANA")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.anaDisponible = true;
            this.respuestaANA = tempdate.response[0] as Respuesta_WS_response;
            this.primaTotalANA = this.respuestaANA.primaTotal;
            this.formGroupCotizacion.get('p_numCotizacionA').setValue(this.respuestaANA.numCotizacion);
            this.hasErrorAna = this.respuestaANA.hasError;
            this.etapa = 2;
          } else {
            this.anaDisponible = false;
            this.hasErrorAna = true;
          }
        this.CiaLoaderA = false;
      // this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.anaDisponible = false;
          this.hasErrorAna = true;
          // this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
  }
  async getVersionesANA() {
    if (this.ana) {
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/GetVersionesANA?p_Marca=" + this.formGroupCotizacion.value.p_Marca + "&p_Modelo=" + this.formGroupCotizacion.value.p_Modelo + "&p_Descripcion=" + this.formGroupCotizacion.value.p_Descripcion + "&p_Version=" + this.formGroupCotizacion.value.p_Version)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.VersionesANA = tempdate as VersionANA_response;
            this.formGroupReCotizacionANA.get('p_VersionANA').setValue(this.VersionesANA.amisSelected);
            this.formGroupCotizacion.get('p_VersionANA').setValue(this.VersionesANA.amisSelected);
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
  }
  async getVersionesGNP() {
    if (this.gnp) {
      this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/GetVersionesGNP?p_Marca=" + this.formGroupCotizacion.value.p_Marca + "&p_Modelo=" + this.formGroupCotizacion.value.p_Modelo + "&p_Descripcion=" + this.formGroupCotizacion.value.p_Descripcion + "&p_Version=" + this.formGroupCotizacion.value.p_Version)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.VersionesGNP = tempdate as VersionGNP_response;
            let result = null; // Variable para almacenar el resultado

            for (let i = 0; i < this.VersionesGNP.transaccionesVehiculo.length; i++) {
              if (this.VersionesGNP.transaccionesVehiculo[i].clave === this.VersionesGNP.amisSelected) {
                result = `${this.VersionesGNP.transaccionesVehiculo[i].clave}_${this.VersionesGNP.transaccionesVehiculo[i].valor}`;
                break; // Rompemos el bucle cuando encontramos una coincidencia
              }
            }

            this.formGroupReCotizacionGNP.get('p_VersionGNP').setValue(result);
            this.formGroupCotizacion.get('p_VersionGNP').setValue(result);



          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
  }
  async cotizaGNP() {
    if (this.gnp) {
      this.gnpDisponible = false;
    // this.operacionRespuesta.EstaEjecutando = true;
    this.primaTotalGNP = 0;
    this.CiaLoaderG = true;

    this.formGroupReCotizacionGNP.get('p_ValorG').setValue(this.formGroupCotizacion.value.p_ValorG);
    this.formGroupReCotizacionGNP.get('p_SumaAseguradaVFG').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVFG);
      if (this.formGroupCotizacion.value.p_ValorG == '5') {
        this.esValorFacturaG = true;
      } else {
        this.esValorFacturaG = false;
      }

    let model = this.formGroupCotizacion.value;
    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/CotizaGNP")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.gnpDisponible = true;
            this.respuestaGNP = tempdate.response[0] as Respuesta_WS_response;
            this.primaTotalGNP = this.respuestaGNP.primaTotal;
            this.formGroupCotizacion.get('p_numCotizacionG').setValue(this.respuestaGNP.numCotizacion);
            this.formGroupEmision.get('p_primaTotalG').setValue(this.respuestaGNP.primaTotal);
            this.formGroupEmision.get('p_ivaG').setValue(this.respuestaGNP.totalIVA);
            this.formGroupEmision.get('p_primaNetaG').setValue(this.respuestaGNP.totalPrimaNeta);
            this.hasErrorGNP = this.respuestaGNP.hasError;
            this.etapa = 2;
          } else {
            this.gnpDisponible = false;
            this.hasErrorGNP = true;
          }
        this.CiaLoaderG = false;
    // this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.gnpDisponible = false;
          this.hasErrorGNP = true;
          // this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
  }
  complementarDatos(idAseguradora) {
    this.etapa = 3;

    this.formGroupEmision.value.p_AseguradoraSeleccionada = idAseguradora;
    this.formGroupEmision.value.p_Correo = this.formGroupCotizacion.value.p_Correo;
    this.formGroupEmision.value.p_Celular = this.formGroupCotizacion.value.p_Celular;
    this.formGroupEmision.value.p_IDGenero = this.formGroupCotizacion.value.p_IDGenero;
    this.formGroupEmision.value.p_FNacimiento = this.formGroupCotizacion.value.p_FNacimiento;
    this.formGroupEmision.value.p_CPostal = this.formGroupCotizacion.value.p_CPostal;
    this.formGroupEmision.value.p_NombreProspecto = this.formGroupCotizacion.value.p_NombreProspecto;
    this.formGroupEmision.value.p_ApellidoPProspecto = this.formGroupCotizacion.value.p_ApellidoPProspecto;
    this.formGroupEmision.value.p_ApellidoMProspecto = this.formGroupCotizacion.value.p_ApellidoMProspecto;
    this.formGroupEmision.patchValue(this.formGroupEmision.value);

    this.getCPostal(this.formGroupEmision.value.p_CPostal);
    this.getRFC();
  }

  editarCotizacion() {
    this.etapa = 1;
    this.obtenerMarcas(1);
    //borramos las versiones vigentes por que cambiaron de auto
    this.formGroupCotizacion.get('p_VersionQUA').setValue('');
    this.formGroupCotizacion.get('p_VersionANA').setValue('');
    this.formGroupCotizacion.get('p_VersionGNP').setValue('');
    this.formGroupCotizacion.get('p_numCotizacionQ').setValue('');
    this.formGroupCotizacion.get('p_numCotizacionA').setValue('');
    this.formGroupCotizacion.get('p_numCotizacionG').setValue('');
    this.formGroupCotizacion.get('p_Valor').setValue(1);
    this.formGroupCotizacion.get('p_ValorQ').setValue(1);
    this.formGroupCotizacion.get('p_ValorA').setValue(1);
    this.formGroupCotizacion.get('p_ValorG').setValue(1);
    this.formGroupCotizacion.get('p_SumaAseguradaVF').setValue(0);
    this.formGroupCotizacion.get('p_SumaAseguradaVFQ').setValue(0);
    this.formGroupCotizacion.get('p_SumaAseguradaVFA').setValue(0);
    this.formGroupCotizacion.get('p_SumaAseguradaVFG').setValue(0);
    // this.formGroupCotizacion.get('p_Marca').setValue(0);
    this.formGroupCotizacion.get('p_Modelo').setValue(0);
    this.formGroupCotizacion.get('p_Descripcion').setValue(0);
    this.formGroupCotizacion.get('p_Version').setValue(0);
    this.formGroupCotizacion.patchValue(this.formGroupCotizacion.value);

    const _enviarCRM = document.querySelector('input[id="p_enviarCRM"]') as HTMLInputElement;
    if (_enviarCRM) {
      if (this.formGroupCotizacion.value.p_enviarCRM === 1) {
        _enviarCRM.checked = true;
      } else {
        _enviarCRM.checked = false;
      }
    }


  }

  guardarCliente() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupEmision.value;
    model.p_Nombre = this.formGroupEmision.value.p_NombreProspecto;
    model.p_APaterno = this.formGroupEmision.value.p_ApellidoPProspecto;
    model.p_AMaterno = this.formGroupEmision.value.p_ApellidoMProspecto;
    model.p_IDCotizacion = this.formGroupCotizacion.value.p_IDCotizacion;
    this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/PutCotizacion")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.limpiarFormulario();
            this.etapa = 1;
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

  }
  async recotizaQualitas(campo) {
    switch (campo) {
      case 'p_ValorQ':
        this.formGroupCotizacion.get('p_ValorQ').setValue(this.formGroupReCotizacionQualitas.value.p_ValorQ);

        this.formGroupCotizacion.value.p_ValorQ = this.formGroupReCotizacionQualitas.value.p_ValorQ;
        if (this.formGroupReCotizacionQualitas.value.p_ValorQ != '5') {
          this.formGroupReCotizacionQualitas.get('p_SumaAseguradaVFQ').setValue(0);
          this.formGroupCotizacion.get('p_SumaAseguradaVFQ').setValue(0);
          this.esValorFactura = false;
        } else {
          this.esValorFactura = true;
          this.formGroupCotizacion.value.p_SumaAseguradaVFQ = this.formGroupReCotizacionQualitas.value.p_SumaAseguradaVFQ;
        }
        break;
      case 'p_SumaAseguradaVF':
        this.formGroupCotizacion.value.p_SumaAseguradaVFQ = this.formGroupReCotizacionQualitas.value.p_SumaAseguradaVFQ;
        await this.cotizaQualitas();
        break;
      case 'p_dMaterialesQ':
        this.formGroupCotizacion.value.p_dMaterialesQ = this.formGroupReCotizacionQualitas.value.p_dMaterialesQ;
        break;
      case 'p_roboTotalQ':
        this.formGroupCotizacion.value.p_roboTotalQ = this.formGroupReCotizacionQualitas.value.p_roboTotalQ;
        break;
      case 'p_RCQ':
        this.formGroupCotizacion.value.p_RCQ = this.formGroupReCotizacionQualitas.value.p_RCQ;
        break;
      case 'p_GMQ':
        this.formGroupCotizacion.value.p_GMQ = this.formGroupReCotizacionQualitas.value.p_GMQ;
        break;
    }
    this.formGroupCotizacion.patchValue(this.formGroupCotizacion.value);
    if (this.formGroupReCotizacionQualitas.value.p_ValorQ != '5') {
      await this.cotizaQualitas();
    }
  }
  async recotizaANA(campo) {
    switch (campo) {
      case 'p_ValorA':
        this.formGroupCotizacion.get('p_ValorA').setValue(this.formGroupReCotizacionANA.value.p_ValorA);

        this.formGroupCotizacion.value.p_ValorA = this.formGroupReCotizacionANA.value.p_ValorA;
        if (this.formGroupReCotizacionANA.value.p_ValorA != '5') {
            this.formGroupReCotizacionANA.get('p_SumaAseguradaVFA').setValue(0);
            this.formGroupCotizacion.get('p_SumaAseguradaVFA').setValue(0);
          this.esValorFacturaA = false;
        } else {
          this.esValorFacturaA = true;
          this.formGroupCotizacion.value.p_SumaAseguradaVFA = this.formGroupReCotizacionANA.value.p_SumaAseguradaVFA;
        }
        break;
      case 'p_SumaAseguradaVF':
        this.formGroupCotizacion.value.p_SumaAseguradaVFA = this.formGroupReCotizacionANA.value.p_SumaAseguradaVFA;
        break;
      case 'p_dMaterialesA':
        this.formGroupCotizacion.value.p_dMaterialesA = this.formGroupReCotizacionANA.value.p_dMaterialesA;
        break;
      case 'p_roboTotalA':
        this.formGroupCotizacion.value.p_roboTotalA = this.formGroupReCotizacionANA.value.p_roboTotalA;
        break;
      case 'p_RCA':
        this.formGroupCotizacion.value.p_RCA = this.formGroupReCotizacionANA.value.p_RCA;
        break;
      case 'p_GMA':
        this.formGroupCotizacion.value.p_GMA = this.formGroupReCotizacionANA.value.p_GMA;
        break;
      case 'p_VersionANA':
        this.formGroupCotizacion.value.p_VersionANA = this.formGroupReCotizacionANA.value.p_VersionANA;
        break;
    }
    this.formGroupCotizacion.patchValue(this.formGroupCotizacion.value);
    if (this.formGroupReCotizacionANA.value.p_ValorA != '5' || campo == 'p_SumaAseguradaVF' ) {
      await this.cotizaANA();
    }
  }
  async recotizaGNP(campo) {
    switch (campo) {
      case 'p_ValorG':
        this.formGroupCotizacion.get('p_ValorG').setValue(this.formGroupReCotizacionGNP.value.p_ValorG);

        this.formGroupCotizacion.value.p_ValorG = this.formGroupReCotizacionGNP.value.p_ValorG;
        if (this.formGroupReCotizacionGNP.value.p_ValorG != '5') {
            this.formGroupReCotizacionGNP.get('p_SumaAseguradaVFG').setValue(0);
            this.formGroupCotizacion.get('p_SumaAseguradaVFG').setValue(0);
          this.esValorFacturaG = false;
        } else {
          this.esValorFacturaG = true;
          this.formGroupCotizacion.value.p_SumaAseguradaVFG = this.formGroupReCotizacionGNP.value.p_SumaAseguradaVFG;
        }
        break;
      case 'p_SumaAseguradaVF':
        this.formGroupCotizacion.value.p_SumaAseguradaVFG = this.formGroupReCotizacionGNP.value.p_SumaAseguradaVFG;
        break;
      case 'p_dMaterialesG':
        this.formGroupCotizacion.value.p_dMaterialesG = this.formGroupReCotizacionGNP.value.p_dMaterialesG;
        break;
      case 'p_roboTotalG':
        this.formGroupCotizacion.value.p_roboTotalG = this.formGroupReCotizacionGNP.value.p_roboTotalG;
        break;
      case 'p_RCG':
        this.formGroupCotizacion.value.p_RCG = this.formGroupReCotizacionGNP.value.p_RCG;
        break;
      case 'p_GMG':
        this.formGroupCotizacion.value.p_GMG = this.formGroupReCotizacionGNP.value.p_GMG;
        break;
      case 'p_VersionGNP':
        this.formGroupCotizacion.value.p_VersionGNP = this.formGroupReCotizacionGNP.value.p_VersionGNP;
        break;
    }
    this.formGroupCotizacion.patchValue(this.formGroupCotizacion.value);
    if (this.formGroupReCotizacionGNP.value.p_ValorG != '5' || campo == 'p_SumaAseguradaVF' ) {
      await this.cotizaGNP();
    }
  }

//#endregion

//#region funciones base

  getCPostal(_cpostal) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.listaMarca = [];
    // const _cpostal = parseInt(event.target.value, 10)

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/GetListaColonias?p_CPostal=" + _cpostal)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaColonias = tempdate.response as Cpostal_response[];
            this.formGroupEmision.value.p_Municipio = this.listaColonias[0].municipio;
            this.formGroupEmision.value.p_Estado = this.listaColonias[0].estado;
            this.formGroupEmision.patchValue(this.formGroupEmision.value);

          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Cotización Autos';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
  getRFC() {
    var ap_paterno = this.formGroupEmision.value.p_ApellidoPProspecto;
    var ap_materno = this.formGroupEmision.value.p_ApellidoMProspecto;
    var nombre = this.formGroupEmision.value.p_NombreProspecto;
    var rfc = this.formGroupEmision.value.p_FNacimiento;
    rfc = (rfc.split('-')[0]).substring(2, 4) + rfc.split('-')[1] + rfc.split('-')[2];
    var sexo = this.formGroupEmision.value.p_IDGenero;
    var estado = "VZ";  //falta validacion de estados solo es para curp
    if (sexo == 1) {
      sexo = 'H';
    } else {
      sexo = 'M';
    }

    var dteNacimiento = rfc;
    //FILTRA ACENTOS
    var ap_pat_f = RFCFiltraAcentos(ap_paterno.toLowerCase());
    var ap_mat_f = RFCFiltraAcentos(ap_materno.toLowerCase());
    var nombre_f = RFCFiltraAcentos(nombre.toLowerCase());
    //GUARDA NOMBRE ORIGINAL PARA GENERAR HOMOCLAVE
    var ap_pat_orig = ap_pat_f;
    var ap_mat_orig = ap_mat_f;
    var nombre_orig = nombre_f;
    //ELIMINA PALABRAS SOBRANTES DE LOS NOMBRES
    ap_pat_f = RFCFiltraNombres(ap_pat_f);
    ap_mat_f = RFCFiltraNombres(ap_mat_f);
    nombre_f = RFCFiltraNombres(nombre_f);

    if (ap_pat_f.length > 0 && ap_mat_f.length > 0) {
      if (ap_pat_f.length < 3) {
        rfc = RFCApellidoCorto(ap_pat_f, ap_mat_f, nombre_f);
      } else {
        rfc = RFCArmalo(ap_pat_f, ap_mat_f, nombre_f);
      }
    }

    if (ap_pat_f.length == 0 && ap_mat_f.length > 0) {
      rfc = RFCUnApellido(nombre_f, ap_mat_f);
    }
    if (ap_pat_f.length > 0 && ap_mat_f.length == 0) {
      rfc = RFCUnApellido(nombre_f, ap_pat_f);
    }

    rfc = RFCQuitaProhibidas(rfc);

    rfc = rfc.toUpperCase() + dteNacimiento + homonimia(ap_pat_orig, ap_mat_orig, nombre_orig);

    rfc = RFCDigitoVerificador(rfc);

    this.formGroupEmision.value.p_RFC = rfc;
    this.formGroupEmision.patchValue(this.formGroupEmision.value);
  }

  setPaquete(event: any) {
    const _paquete = parseInt(event.target.value, 10);
    this.paquete = _paquete;
  }

  setValor(_valor){
    this.formGroupCotizacion.get('p_ValorQ').setValue(_valor);
    this.formGroupCotizacion.get('p_ValorA').setValue(_valor);
    this.formGroupCotizacion.get('p_ValorG').setValue(_valor);
    if (_valor != '5') {
      this.formGroupCotizacion.get('p_SumaAseguradaVF').disable();
      this.formGroupCotizacion.get('p_SumaAseguradaVF').setValue(0);
      this.formGroupCotizacion.get('p_SumaAseguradaVFQ').setValue(0);
      this.formGroupCotizacion.get('p_SumaAseguradaVFA').setValue(0);
      this.formGroupCotizacion.get('p_SumaAseguradaVFG').setValue(0);
    } else {
      this.formGroupCotizacion.get('p_SumaAseguradaVF').enable();
    }
  }

  //#region IMPRIMIR y PDF

  generarPDF() {
    const uniqueId = this.generarIdUnico();

    const options = {
      filename: `Cotizacion-${this.formGroupCotizacion.value.p_IDCotizacion}-${this.todayFormatted}-${uniqueId}.pdf`,
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { orientation: 'portrait' }
    };

    const element = document.getElementById('htmlPrint');

    html2pdf()
      .set(options)
      .from(element)
      .save();
  }

  generarPDFtoWhatSapp() {
    const uniqueId = this.generarIdUnico();

    const options = {
      filename: `Cotizacion-${this.formGroupCotizacion.value.p_IDCotizacion}-${this.todayFormatted}-${uniqueId}.pdf`,
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { orientation: 'portrait' }
    };

    const element = document.getElementById('htmlPrint');

    html2pdf()
      .set(options)
      .from(element)
      .output('blob')
      .then(async pdf => {
        const base64Data = await this.getBase64FromBlob(pdf);

        const fileModel = {
          fileName: options.filename,
          base64Content: base64Data
        };

        this.services.HttpPost(fileModel, this.modelo_configuracion.serviciosOperaciones + "/Generales/UploadPdfCotizacion")
          .subscribe(
            (data: any) => {
              if (data.success) {
                // data.pdfPath contiene la URL del archivo PDF generado en el servidor
                let urlProd = this.modelo_configuracion.urlPdfCotizacion + options.filename;
                // console.log(this.modelo_configuracion.urlPdfCotizacion + options.filename);
                // console.log('URL del archivo PDF:', data.pdfPath);

                // Generar el enlace de WhatsApp
                const numeroDeTelefono = this.formGroupCotizacion.value.p_Celular;  // Reemplaza con el número de teléfono al que deseas enviar el mensaje
                const mensaje = `¡Hola! Aquí tienes la cotización de tu Vehículo: ${this.formGroupCotizacion.value.p_Marca} ${this.formGroupCotizacion.value.p_Descripcion} ${this.formGroupCotizacion.value.p_Modelo} ${urlProd}`;

                const enlaceWhatsApp = `https://api.whatsapp.com/send?phone=${numeroDeTelefono}&text=${encodeURIComponent(mensaje)}`;

                // Abrir el enlace en una nueva ventana (solo funcionará en dispositivos móviles con WhatsApp instalado)
                window.open(enlaceWhatsApp, '_blank');
              } else {
                console.error('Error al subir y guardar el archivo PDF en el servidor:', data.error);
              }
            },
            (error) => {
              console.error('Error en la solicitud HTTP:', error);
            }
          );
      });
  }
  
  async getBase64FromBlob(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        // Corregir el acceso a la propiedad 'result'
        resolve(reader.result as string);
      };
      reader.onerror = reject;
    });
  }
   
  generarIdUnico() {
    // Generar un número aleatorio y convertirlo a hexadecimal
    const randomId = Math.random().toString(16).substring(2);
  
    // Puedes ajustar la longitud del identificador según tus necesidades
    const uniqueId = randomId.substr(0, 8);
  
    return uniqueId;
  }

  
  imprimirPDF() {
    this.modalCuponPDFH = this.modalService.open(this.ModalCuponPDFH, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  _imprimirPDF() {
    const modalContent = document.getElementById('htmlPrint');
    if (modalContent) {
      const ventanaImpresion = window.open('', '_blank');
      const estilos = Array.from(document.getElementsByTagName('link'));
      const estilosHTML = estilos.map((estilo) => estilo.outerHTML).join('');

      ventanaImpresion.document.write(`<html><head><title>Imprimir Cotización</title>${estilosHTML}</head><body><div class="row m-4"><div class="col-md-12"><div class="card px-2">`);
      ventanaImpresion.document.write(modalContent.innerHTML);
      ventanaImpresion.document.write('</div></div></div></body></html><script>window.print(); </script>');

    }
  }
  cerrarModalPDF() {
    this.modalCuponPDFH.close();
  }
//#endregion


  onCheckboxChange(event: any) {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      this.formGroupCotizacion.get('p_Paquete').setValue(3);
      this.formGroupCotizacion.get('p_Paquete').disable();
    } else {
      this.formGroupCotizacion.get('p_Paquete').setValue(1);
      this.formGroupCotizacion.get('p_Paquete').enable();
    }
  }

  onCheckboxChangeCH(event: any) {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      this.titularEsConductoH = true;
      this.formGroupCotizacion.get('p_IDGeneroCH').disable();
      this.formGroupCotizacion.get('p_FNacimientoCH').disable();
      this.formGroupCotizacion.get('p_CPostalCH').disable();
      this.formGroupCotizacion.get('p_IDGeneroCH').setValue(0);
      this.formGroupCotizacion.get('p_FNacimientoCH').setValue('');
      this.formGroupCotizacion.get('p_CPostalCH').setValue('');
    } else {
      this.titularEsConductoH = false;
      this.formGroupCotizacion.get('p_IDGeneroCH').enable();
      this.formGroupCotizacion.get('p_FNacimientoCH').enable();
      this.formGroupCotizacion.get('p_CPostalCH').enable();
    }
  }

  onCheckboxChangeBP(event: any) {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      this.beneficiarioPreferente = true;
      this.formGroupEmision.get('p_BeneficiarioPreferente').disable();
    } else {
      this.beneficiarioPreferente = false;
      this.formGroupEmision.get('p_BeneficiarioPreferente').enable();
    }
  }

  regresarAlComparativo() {
    this.etapa = 2;
  }

  abrirMenu(menu: string) {
    this.menuService.abrirMenuPorfuera(menu); // Llama a la función del servicio
  }

  emitirGuardarCliente() {
    this.operacionRespuesta.EstaEjecutando = true;

    this.formGroupEmision.get('p_numeroEmpleado').setValue(this.formGroupCotizacion.value.p_numeroEmpleado);
    this.formGroupEmision.get('p_claveAsesor').setValue(this.formGroupCotizacion.value.p_claveAsesor);
    this.formGroupEmision.get('p_Modelo').setValue(this.formGroupCotizacion.value.p_Modelo);
    this.formGroupEmision.get('p_Marca').setValue(this.formGroupCotizacion.value.p_Marca);
    this.formGroupEmision.get('p_Descripcion').setValue(this.formGroupCotizacion.value.p_Descripcion);
    this.formGroupEmision.get('p_Version').setValue(this.formGroupCotizacion.value.p_Version);
    this.formGroupEmision.get('p_FormaPago').setValue(this.formGroupCotizacion.value.p_FormaPago);
    this.formGroupEmision.get('p_Paquete').setValue(this.formGroupCotizacion.value.p_Paquete);
    this.formGroupEmision.get('p_enviarCRM').setValue(this.formGroupCotizacion.value.p_enviarCRM);
    this.formGroupEmision.get('p_dMateriales').setValue(this.formGroupCotizacion.value.p_dMateriales);
    this.formGroupEmision.get('p_roboTotal').setValue(this.formGroupCotizacion.value.p_roboTotal);
    this.formGroupEmision.get('p_RC').setValue(this.formGroupCotizacion.value.p_RC);
    this.formGroupEmision.get('p_GM').setValue(this.formGroupCotizacion.value.p_GM);
    this.formGroupEmision.get('p_dMaterialesQ').setValue(this.formGroupCotizacion.value.p_dMaterialesQ);
    this.formGroupEmision.get('p_roboTotalQ').setValue(this.formGroupCotizacion.value.p_roboTotalQ);
    this.formGroupEmision.get('p_dMaterialesA').setValue(this.formGroupCotizacion.value.p_dMaterialesA);
    this.formGroupEmision.get('p_roboTotalA').setValue(this.formGroupCotizacion.value.p_roboTotalA);
    this.formGroupEmision.get('p_dMaterialesG').setValue(this.formGroupCotizacion.value.p_dMaterialesG);
    this.formGroupEmision.get('p_roboTotalG').setValue(this.formGroupCotizacion.value.p_roboTotalG);
    
    this.formGroupEmision.get('p_Valor').setValue(this.formGroupCotizacion.value.p_Valor);
    this.formGroupEmision.get('p_SumaAseguradaVF').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVF);
    this.formGroupEmision.get('p_ValorQ').setValue(this.formGroupCotizacion.value.p_ValorQ);
    this.formGroupEmision.get('p_SumaAseguradaVFQ').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVFQ);
    this.formGroupEmision.get('p_ValorA').setValue(this.formGroupCotizacion.value.p_ValorA);
    this.formGroupEmision.get('p_SumaAseguradaVFA').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVFA);
    this.formGroupEmision.get('p_ValorG').setValue(this.formGroupCotizacion.value.p_ValorG);
    this.formGroupEmision.get('p_SumaAseguradaVFG').setValue(this.formGroupCotizacion.value.p_SumaAseguradaVFG);
    
    this.formGroupEmision.get('p_RCQ').setValue(this.formGroupCotizacion.value.p_RCQ);
    this.formGroupEmision.get('p_GMQ').setValue(this.formGroupCotizacion.value.p_GMQ);
    this.formGroupEmision.get('p_RCA').setValue(this.formGroupCotizacion.value.p_RCA);
    this.formGroupEmision.get('p_GMA').setValue(this.formGroupCotizacion.value.p_GMA);
    this.formGroupEmision.get('p_RCG').setValue(this.formGroupCotizacion.value.p_RCG);
    this.formGroupEmision.get('p_GMG').setValue(this.formGroupCotizacion.value.p_GMG);
    this.formGroupEmision.get('p_FNacimientoCH').setValue(this.formGroupCotizacion.value.p_FNacimientoCH);
    this.formGroupEmision.get('p_IDGeneroCH').setValue(this.formGroupCotizacion.value.p_IDGeneroCH);
    this.formGroupEmision.get('p_CPostalCH').setValue(this.formGroupCotizacion.value.p_CPostalCH);
    this.formGroupEmision.get('p_VersionQUA').setValue(this.formGroupCotizacion.value.p_VersionQUA);
    this.formGroupEmision.get('p_VersionANA').setValue(this.formGroupCotizacion.value.p_VersionANA);
    this.formGroupEmision.get('p_VersionGNP').setValue(this.formGroupCotizacion.value.p_VersionGNP);
    this.formGroupEmision.get('p_Celular').setValue(this.formGroupCotizacion.value.p_Celular);
    this.formGroupEmision.get('p_IDCotizacion').setValue(this.formGroupCotizacion.value.p_IDCotizacion);

    this.formGroupEmision.get('p_numCotizacionQ').setValue(this.formGroupCotizacion.value.p_numCotizacionQ);
    this.formGroupEmision.get('p_numCotizacionA').setValue(this.formGroupCotizacion.value.p_numCotizacionA);
    this.formGroupEmision.get('p_numCotizacionG').setValue(this.formGroupCotizacion.value.p_numCotizacionG);


    let model = this.formGroupEmision.value;

    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/PostEmision")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].hasError == false) {
            this.respuestaEmisionWS = tempdate.response[0] as Respuesta_WS_Emision_response;
            this.etapa = 4;
          } else {
            this.openMensajes(tempdate.response[0].txtError, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );


  }

  descargarPoliza() {
    // const url = this.respuestaEmisionWS.URL_Local;
    const url = this.respuestaEmisionWS.link;

    // Verificamos si la URL está definida
    if (url) {
      // Abrir la URL en una nueva ventana del navegador
      window.open(url, '_blank');
    } else {
      // Manejar el caso en el que la URL no esté definida
      console.error('La URL del archivo PDF no está definida.');
      // Puedes mostrar un mensaje de error o tomar otras medidas según tus necesidades.
    }

  }

  enviarPoliza() {

    var phoneNumber = this.formGroupEmision.value.p_Celular; // Número de teléfono de destino
    var messageText = 'Esta es tu póliza '+ this.respuestaEmisionWS.poliza +', descárgala desde aquí:';
    var documentLink = this.respuestaEmisionWS.urL_Local;
      const whatsappText = `${messageText} ${documentLink}`;

      // Codifica el texto del mensaje para que sea seguro en una URL
  const encodedMessage = encodeURIComponent(whatsappText);

  // Crea la URL de WhatsApp con el número de teléfono y el mensaje
  const whatsappUrl = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${encodedMessage}&app_absent=0`;

  // Abre la URL en una nueva ventana o pestaña
  window.open(whatsappUrl, '_blank');
    // const whatsappText = `${messageText} ${documentLink}`;
    // const whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(whatsappText)}`;
    // return this.sanitizer.bypassSecurityTrustUrl(whatsappUrl);

  }

  irInicio()
  {
    this.router.navigate(['/']);
  }

  getAseguradorasDisponibles() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Generales/GetAseguradorasDisponibles?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaAseguradoras = tempdate.response;

          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
//#endregion


}
