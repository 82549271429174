import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Cotizacion_salud_response, Cpostal_response, Respuesta_WS_response, VersionANA_response, VersionGNP_response } from 'src/model/Catalogos/Cotizacion';
import { Descripcion_modelo_response, Marca_modelo_response, Modelos_modelo_response, Version_modelo_response } from 'src/model/Catalogos/Ramos';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as html2pdf from 'html2pdf.js';
import { AuthIdentity } from 'src/app/guards/AuthIdentity';
import { MenuService } from 'src/app/menu.service';
import { Respuesta_WS_Emision_response } from 'src/model/Catalogos/Emision';
import { Lista_aseguradoras_modelo_response } from 'src/model/Catalogos/Generales';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  providers: [ServiceGenerico]
})
export class LandingComponent implements OnInit {
  modelo_configuracion: ServiciosRutas;
  operacionRespuesta: RespuestaGenerica;
  todayFormatted: string;
  tomorrowFormatted: string;
  formGroupCotizacion: FormGroup;
  listaMarca: any[];
  listaColonias: Cpostal_response[];
  modalrefMsg: NgbModalRef;
  datosCotizacion: Cotizacion_salud_response;
  etapa: number = 1; 
  constructor(
    private services: ServiceGenerico,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private menuService: MenuService
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    this.todayFormatted = today.toISOString().split('T')[0]; // Formato: AAAA-MM-DD
    this.tomorrowFormatted = tomorrow.toISOString().split('T')[0]; // Formato: AAAA-MM-DD
  }

  ngOnInit() {
    this.inicializarFormCotiza();
  }

  inicializarFormCotiza() {

    this.formGroupCotizacion = this.fb.group({
      p_claveAsesor: ["5555"],
      p_numeroEmpleado: [""],
      p_NombreProspecto: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\u00C0-\u017F ]*$/)]],
      p_ApellidoPProspecto: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\u00C0-\u017F ]*$/)]],
      p_ApellidoMProspecto: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\u00C0-\u017F ]*$/)]],
      p_Correo: ["", Validators.compose([Validators.required, Validators.email])],
      p_Celular: ["", [Validators.required, this.validateCelular]],
      p_CPostal: ["", [Validators.required, this.validateCPostal]],
      p_FNacimiento: ["", [Validators.required, this.ageRangeValidator(0, 100)]],
      p_IDGenero: [0, [Validators.required, this.validateGenero]],
      p_TipoNomina: [0],
      p_CentroTrabajo: [''],
      p_IDCotizacion: [0],
      p_Municipio: [""],
      p_Estado: [""],
    });
  }

  ageRangeValidator(minAge: number, maxAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // No error if the field is empty
      }

      const birthDate = new Date(control.value);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();

      if (age < minAge || age > maxAge) {
        return { ageRange: true }; // Return error if age is out of range
      }

      return null; // No error if age is within range
    };
  }

  validateClaveAsesor(control) {
    const value = control.value.trim(); // Elimina espacios en blanco al inicio y al final

    if (value === '') {
      return { 'required': true };
    } else {
      return null;
    }
  }

  validateCPostal(control) {
    const value = control.value;

    if (value === "") {
      return null;
    }
    else if (value.length === 5 && /^\d+$/.test(value)) {
      return null;
    } else {
      return { 'invalidCpostal': true };
    }
  }

  validateGenero(control) {
    if (control.value === 0) {
      return { 'invalidValue': true };
    }
    return null;
  }

  validateCelular(control) {
    const value = control.value;

    if (value === "") {
      return null;
    }
    else if (value.length === 10 && /^\d+$/.test(value)) {
      return null;
    } else {
      return { 'invalidCelular': true };
    }
  }

  getCPostal(_cpostal) {
    this.operacionRespuesta.EstaEjecutando = true;

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/GetListaColonias?p_CPostal=" + _cpostal)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaColonias = tempdate.response as Cpostal_response[];
            this.formGroupCotizacion.value.p_Municipio = this.listaColonias[0].municipio;
            this.formGroupCotizacion.value.p_Estado = this.listaColonias[0].estado;
            this.formGroupCotizacion.patchValue(this.formGroupCotizacion.value);

          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  limpiarFormulario() {
    this.formGroupCotizacion.reset({
      p_claveAsesor: '5555',
      p_numeroEmpleado: '',
      p_NombreProspecto: '',
      p_ApellidoPProspecto: '',
      p_ApellidoMProspecto: '',
      p_IDGenero: 0,
      p_FNacimiento: '',
      p_TipoNomina: 0,
      p_CentroTrabajo: '',
      p_CPostal: '',
      p_Municipio: 0,
      p_Estado: 0,
      p_Correo: '',
      p_Celular: '',
    });
  }

  async cotizar() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.formGroupCotizacion.get('p_IDCotizacion').setValue(0);
   
    let model = this.formGroupCotizacion.value;

    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/CotizacionSalud/PostCotizacionSalud")
      .subscribe(
        async (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.datosCotizacion = tempdate.response as Cotizacion_salud_response;

            this.formGroupCotizacion.get('p_IDCotizacion').setValue(tempdate.response[0]._IDCotizacion);
            this.openMensajes(tempdate.response[0].msg, false, '');

          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Cotización Salud';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

}
