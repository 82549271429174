import { Component, Input, OnInit } from '@angular/core';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { Lista_negocios_modelo_response, Lista_ramos_x_negocio_modelo_response } from 'src/model/Catalogos/Generales';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { MenuComponent } from 'src/app/menu/menu.component';

@Component({
  selector: 'app-lista-ramos',
  templateUrl: './lista-ramos.component.html',
  styleUrls: ['./lista-ramos.component.css'],
  providers: [ServiceGenerico]
})
export class ListaRamosComponent implements OnInit {
  @Input() idNegocio: number;
  public operacionRespuesta: RespuestaGenerica;
  private modelo_configuracion: ServiciosRutas;
  listaRamos: Lista_ramos_x_negocio_modelo_response[];
  opcionMenu: number;
  Negocio: Lista_negocios_modelo_response = new Lista_negocios_modelo_response;

  constructor(
    private services: ServiceGenerico,
    private menuComponent: MenuComponent,
  ) { 
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.getRamosPorNegocio();
    this.verDetalleNegocio();
  }
  ngOnChanges() {
    if (this.idNegocio) {
      this.getRamosPorNegocio();
      this.verDetalleNegocio();
    }
  }



  getRamosPorNegocio() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaRamosxNegocios?p_idNegocio=" + this.idNegocio)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaRamos = tempdate.response as Lista_ramos_x_negocio_modelo_response[];
          } else {
            this.listaRamos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

  }

  verDetalleNegocio() {
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetNegocio_x_id?p_idNegocio=" + this.idNegocio)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Negocio = tempdate.response[0] as Lista_negocios_modelo_response;
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  cotizar(ramo: string) {
      this.menuComponent.abrirMenu(ramo);
  }
}
