import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login/login.component';
import { MenuComponent } from './menu/menu.component';
import { AppRoutingModule } from './app.routing';
import { AuthGuard } from "../app/guards/AuthGuard";
import { JwtModule } from '@auth0/angular-jwt';
import { ModuloModalMensajeComponent } from './shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { ModuloModalAdvertenciaComponent } from './shared/modulo-modal-advertencia/modulo-modal-advertencia.component';
import { AuthService } from 'src/services/auth.service';
import { ThemeConstants } from './@espire/shared/config/theme-constant';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModuloSpinerLoginComponent } from './shared/modulo-spiner-login/modulo-spiner-login.component';
import { ModuloMensajesComponent } from './shared/modulo-mensajes/modulo-mensajes.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ModuloCambioContrasenaComponent } from './login/modulo-cambio-contrasena/modulo-cambio-contrasena.component';
import { ListaRamosComponent } from './cotizador/lista-ramos/lista-ramos.component';
import { CatalogosComponent } from './catalogos/catalogos/catalogos.component';
import { NegociosComponent } from './catalogos/negocios/negocios.component';
import { AutosComponent } from './ramos/autos/autos.component';
import { ListaCotizacionesComponent } from './cotizador/lista-cotizaciones/lista-cotizaciones.component';
import { CampoMensajesValidacionesComponent } from './shared/campo-mensajes-validaciones/campo-mensajes-validaciones.component';
import { SaludComponent } from './ramos/salud/salud.component';
import { VidaComponent } from './ramos/vida/vida.component';
import { MenuService } from './menu.service';
import { ListaPolizasComponent } from './produccion/lista-polizas/lista-polizas.component';
import { PerfilComponent } from './perfil/perfil.component';
import { LandingComponent } from './landing/landing.component';

/* jwt getToken */
export function obtenerToken(): string {
  return localStorage.getItem("jwt");
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    MenuComponent,
    ModuloSpinerLoginComponent,
    ModuloModalMensajeComponent,
    ModuloModalAdvertenciaComponent,
    ModuloMensajesComponent,
    UsuariosComponent,
    ModuloCambioContrasenaComponent,
    ListaRamosComponent,
    CatalogosComponent,
    NegociosComponent,
    AutosComponent,
    ListaCotizacionesComponent,
    CampoMensajesValidacionesComponent,
    SaludComponent,
    VidaComponent,
    ListaPolizasComponent,
    PerfilComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: obtenerToken,
        // whitelistedDomains: ["localhost:44394"],
        // blacklistedRoutes: ["localhost:44394"],
        whitelistedDomains: ["mcb.api.duranm.me"],
        // whitelistedDomains: ["api-multicotizador.mcb.uno"],
      },
    }),
  ],
  entryComponents: [
    ModuloModalMensajeComponent
  ],
  providers: [AuthGuard, ThemeConstants, AuthService, MenuService],
  bootstrap: [AppComponent]
})
export class AppModule { }

