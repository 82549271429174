export enum PlanVidaEnum {
    Esencial = 4,
    Salud = 5,
    Accidentes = 6,
    Integral = 7,
}

export class CatalogoPaquete {
    idTipoRiesgoVida: number | null;
    nombre: string;
    estado: boolean | null;
}

export class CatalogoPlanVida {
    idTipoPlanVida: number | null;
    nombre: string;
    estado: boolean | null;
}

export class TarifasVidaResponse {
    esencial: TarifasModelo;
    salud: TarifasModelo;
    accidentes: TarifasModelo;
    integral: TarifasModelo;
}

export class TarifasModelo {
    tarifaId: number | null;
    Edad: number | null;
    RiesgoId: number | null;
    riesgo: string;
    planId: number | null;
    plan: string;
    costo100: number | null;
    costo125: number | null;
    costo150: number | null;
    costo175: number | null;
    costo200: number | null;
    costo225: number | null;
    costo250: number | null;
    costo275: number | null;
    costo300: number | null;
    costo350: number | null;
    costo400: number | null;
    costo450: number | null;
    costo500: number | null;
    costo550: number | null;
    costo600: number | null;
    costo650: number | null;
    costo700: number | null;
}

export class TarifasVidaPresentador {

    //#region   Propiedades
    index: number;
    pagoQuincenal: number;
    planId: number | null;
    pagoEsencial: number | null;
    pagoSalud: number | null;
    pagoAccidentes: number | null;
    pagoIntegral: number | null;
    renglonSeleccinado: boolean;
    planEsencial: boolean;
    planSalud: boolean;
    planAccidentes: boolean;
    planIntegral: boolean;
    //#endregion  

    //#region Constructor
    constructor(index: number, pago: number, esencial: number, salud: number, accidentes: number, itegral: number) {
        this.index = index;
        this.pagoQuincenal = pago;
        this.pagoEsencial =   (esencial   != null || esencial   != undefined) ? esencial : 0;
        this.pagoSalud =      (salud      != null || salud      != undefined) ? salud : 0;
        this.pagoAccidentes = (accidentes != null || accidentes != undefined) ? accidentes : 0;
        this.pagoIntegral =   (itegral    != null || itegral    != undefined) ? itegral : 0;
        this.renglonSeleccinado = false;
        this.planEsencial = false;
        this.planSalud = false;
        this.planAccidentes = false;
        this.planIntegral = false;
    }
    //#endregion

}

export class CoberturaVidaResponse {
    coberturaId: number | null;
    nombreCobertura: string;
    esencial: boolean;
    salud: boolean;
    accidentes: boolean;
    integral: boolean;
    planEsencial: boolean = false;
    planSalud: boolean = false;
    planAccidentes: boolean = false;
    planIntegral: boolean = false;
}

export class CotizarVidaInsertarRequest {
    p_nombreProspecto: string;
    p_correo: string;
    p_celular: string;
    p_cPostal: string;
    p_fNacimiento: Date | null;
    p_idGenero: number | null;
    p_paqueteId: number | null;
    p_tipoPlanVidaId: number | null;
    p_pagoQuincenal: number | null;
    p_sumaAsegurada: number | null;
    p_id: number | null;
}

export class CotizarVidaUpdateRequest {
    p_id: number | null;
    p_nombre: string;
    p_aPaterno: string;
    p_aMaterno: string;
    p_rfc: string;
    p_Telefono: string;
    p_celular: string;
    p_fNacimiento: Date | null;;
    p_cPostal: string;
    p_correo: string;
}