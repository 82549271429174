import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_negocios_modelo_response, Lista_perfiles_modelo_response } from 'src/model/Catalogos/Generales';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-negocios',
  templateUrl: './negocios.component.html',
  styleUrls: ['./negocios.component.css'],
  providers: [ServiceGenerico]
})
export class NegociosComponent implements OnInit {
  @ViewChild("modalAddNegocio", { static: false }) modalAddNegocio: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarNegocio: NgbModalRef;
  modalEliminarNegocio: NgbModalRef;

  formGroupAgregarNegocio: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  public negocioId: string = "";

  private modelo_configuracion: ServiciosRutas;
  listaNegocios: Lista_negocios_modelo_response[];
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _idNegocio: number;
  private _nombreNegocio: any;
  Negocio: Lista_negocios_modelo_response;
  listaPerfiles: Lista_perfiles_modelo_response[];
  selectedCheckboxes: string[] = []; // Array para almacenar los valores seleccionados

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerNegocios();
    this.inicializarFormAgregarNegocio();
    // this.getListaNegocios();

  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerNegocios() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaNegocios?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaNegocios = [] = tempdate.response as Lista_negocios_modelo_response[];
            this.renderTabla();
          } else {
            this.listaNegocios = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarNegocio() {
    this.formGroupAgregarNegocio = this.fb.group({
      p_nombre: ["", Validators.required], 
      p_descripcion: [""],
    });

  }

  agregarNegocio() {
    this.funcionalidad = 'Nuevo';
    this._idNegocio = 0;
    this.openModalFormNegocio(true);
  }

  GuardarNegocio() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarNegocio.value;
    model.p_idNegocio = this._idNegocio;
    
    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostNegocio")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerNegocios();
              this.modalAgregarNegocio.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutNegocio")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerNegocios();
              this.modalAgregarNegocio.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }

  verDetalleNegocio(idNegocio) {
    this.funcionalidad = 'Ver';
    this.esEditable = false;
    this.openModalFormNegocio(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetNegocio_x_id?p_idNegocio=" + idNegocio)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Negocio = tempdate.response as Lista_negocios_modelo_response;
            this.formGroupAgregarNegocio.value.p_nombre = this.Negocio[0].nombre;
            this.formGroupAgregarNegocio.value.p_descripcion = this.Negocio[0].descripcion;
            this.formGroupAgregarNegocio.patchValue(this.formGroupAgregarNegocio.value);
          } else {
            this.listaNegocios = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  editarNegocio(idNegocio) {
    this.funcionalidad = 'Editar';
    this._idNegocio = idNegocio;
    this.openModalFormNegocio(true);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetNegocio_x_id?p_idNegocio=" + idNegocio)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Negocio = tempdate.response as Lista_negocios_modelo_response;
            this.formGroupAgregarNegocio.value.p_nombre = this.Negocio[0].nombre;
            this.formGroupAgregarNegocio.value.p_descripcion = this.Negocio[0].descripcion;
            this.formGroupAgregarNegocio.patchValue(this.formGroupAgregarNegocio.value);
          } else {
            this.listaNegocios = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarNegocio(idNegocio) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteNegocio?p_IDNegocio=" + idNegocio)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerNegocios();
            this.modalEliminarNegocio.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  openModalFormNegocio(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarNegocio.reset({ p_nombre: '', p_descripcion: ''});

    this.modalAgregarNegocio = this.modalService.open(this.modalAddNegocio, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idNegocio, nombreCat) {
    this._idNegocio = idNegocio;
    this._nombreNegocio = nombreCat;
    this.modalEliminarNegocio = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Negocios';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
  
  // getListaNegocios() {
  //   this.operacionRespuesta.EstaEjecutando = true;
  //   this.services
  //     .HttpGet(
  //       this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaNegocios?p_estado=1")
  //     .subscribe(
  //       (tempdate) => {
  //         if (tempdate) {
  //           this.listaNegocios = tempdate.response as Lista_negocios_modelo_response[];
  //         } 
  //         this.operacionRespuesta.EstaEjecutando = false;
  //       },
  //       async (err) => {
  //         this.operacionRespuesta.EstaEjecutando = false;
  //       }
  //     );
  // }

  onCheckboxChange(event: any) {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      // Agregar el valor al array de checkboxes seleccionados
      this.selectedCheckboxes.push(checkboxValue);
    } else {
      // Eliminar el valor del array de checkboxes seleccionados
      const index = this.selectedCheckboxes.indexOf(checkboxValue);
      if (index > -1) {
        this.selectedCheckboxes.splice(index, 1);
      }
    }
    console.log(this.selectedCheckboxes); // Mostrar los checkboxes seleccionados en la consola
  }
}
