import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private opcionMenuSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  opcionMenu$: Observable<number> = this.opcionMenuSubject.asObservable();


  private opcionMenu: number = 0;
  _idNegocio: number;
  _bndCatalogo: boolean;

  abrirMenu(menu: string) {
    this.opcionMenu;
    if (menu.includes("listaRamos")) {
      this._idNegocio = parseInt(menu.split('_')[1]);
      menu = 'listaRamos';
    }

    switch (menu) {
      case 'usuarios':
        this.opcionMenu = 1;
        $('.font-weight-bold').removeClass('font-weight-bold');
        $('#' + menu).addClass('font-weight-bold');
        break;
      case 'listaRamos':
        this.opcionMenu = 2;
        $('.font-weight-bold').removeClass('font-weight-bold');
        $('#listaRamos_' + this._idNegocio).addClass('font-weight-bold');
        break;
      case 'catalogos':
        this.opcionMenu = 3;
        $('.font-weight-bold').removeClass('font-weight-bold');
        $('#' + menu).addClass('font-weight-bold');
        if (this._bndCatalogo == true) {
          this._bndCatalogo = false;
        } else {
          this._bndCatalogo = true;
        }
        break;
      case 'Autos':
        this.opcionMenu = 4;
        break;
      case 'listado':
        this.opcionMenu = 5;
        $('.font-weight-bold').removeClass('font-weight-bold');
        $('#' + menu).addClass('font-weight-bold');
        break;
      case 'Salud':
        this.opcionMenu = 6;
        break;
      case 'Vida':
        this.opcionMenu = 7;
        break;
      case 'Polizas':
        this.opcionMenu = 8;
        break;
      case 'perfil':
        this.opcionMenu = 9;
        break;
      default:
        this.opcionMenu = 0; // Opcional: asignar un valor por defecto si no coincide con ninguna opción.
        break;
    }

    return this.opcionMenu;

  }

  abrirMenuPorfuera(menu: string) {
    this.opcionMenu;
    if (menu.includes("listaRamos")) {
      this._idNegocio = parseInt(menu.split('_')[1]);
      menu = 'listaRamos';
    }

    switch (menu) {
      case 'usuarios':
        this.opcionMenu = 1;
        $('.font-weight-bold').removeClass('font-weight-bold');
        $('#' + menu).addClass('font-weight-bold');
        break;
      case 'listaRamos':
        this.opcionMenu = 2;
        $('.font-weight-bold').removeClass('font-weight-bold');
        $('#listaRamos_' + this._idNegocio).addClass('font-weight-bold');
        break;
      case 'catalogos':
        this.opcionMenu = 3;
        $('.font-weight-bold').removeClass('font-weight-bold');
        $('#' + menu).addClass('font-weight-bold');
        if (this._bndCatalogo == true) {
          this._bndCatalogo = false;
        } else {
          this._bndCatalogo = true;
        }
        break;
      case 'Autos':
        this.opcionMenu = 4;
        break;
      case 'listado':
        this.opcionMenu = 5;
        $('.font-weight-bold').removeClass('font-weight-bold');
        $('#' + menu).addClass('font-weight-bold');
        break;
      case 'Salud':
        this.opcionMenu = 6;
        break;
      case 'Vida':
        this.opcionMenu = 7;
        break;
      default:
        this.opcionMenu = 0; // Opcional: asignar un valor por defecto si no coincide con ninguna opción.
        break;
    }


  }
}
