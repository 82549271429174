import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-catalogos',
  templateUrl: './catalogos.component.html',
  styleUrls: ['./catalogos.component.css']
})
export class CatalogosComponent implements OnInit {
  @Input() idCatalogo: boolean;
  opcionMenu: string;

  constructor() { }

  ngOnInit() {   
    this.abrirCatalogo('menuCatalogo');
  }

  ngOnChanges() {
    this.abrirCatalogo('menuCatalogo');

  }

  abrirCatalogo(catalogo: string){

    switch (catalogo) {
      case 'menuCatalogo':
        this.opcionMenu = 'menuCatalogo';
        break;
      case 'Negocios':
        this.opcionMenu = 'negocios';
        break;
      default:
        this.opcionMenu = 'menuCatalogo'; // Opcional: asignar un valor por defecto si no coincide con ninguna opción.
        break;
    }

  }
}
