import { NgModule } from "@angular/core";

import { Routes, RouterModule } from "@angular/router";

// Layouts

import { MenuComponent } from "./menu/menu.component";
import { LoginComponent } from "./login/login/login.component";
import { ModuloCambioContrasenaComponent } from "./login/modulo-cambio-contrasena/modulo-cambio-contrasena.component";
import { AutosComponent } from "./ramos/autos/autos.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: '', component: MenuComponent },
  { path: 'recuperar-contrasena/:token', component: ModuloCambioContrasenaComponent },
  // { path: 'seguro/autos', component: AutosComponent },
  { path: "**", redirectTo: "/" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
