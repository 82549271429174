export enum FormasDePagoEnum{
    anual = 1,
    semestral = 2,
    trimestral = 3,
    mensual = 4,
    dxn = 5,
    Quincenal = 6,
}

export class TarifaSalud {
    tarifa_saludId: number | null;
    nombre: string;
    estado: boolean | null;
}

export class FormaDepago {
    formaPagoId: number | null;
    nombre: string;
    estado: boolean | null;
}

export class TarifasVidaResponse {
    tarifaSaludId: number | null;
    edad: number | null;
    costo: number | null;
}

export class FamiliarModal {
    p_id: number | null;
    p_fechaNacimiento: Date | null;
    p_generoId: number | null;
    p_edad: number | null;

    constructor(id: number) {
        this.p_id = id;
        this.p_edad = 0;
    }
}

export class PlanFamiliarRegistros {

    public lstPersonas: FamiliarModal[] = [];

    constructor(personas: number) {
        this.lstPersonas = [];
        for (var i = 0; i < personas; i++) {
            this.lstPersonas.push(new FamiliarModal(i));
        }
    }
}

export class SaludInsertarRequest {
    p_numeroEmpleado  : string;
    p_nombreProspecto : string;
    p_correo          : string;
    p_celular         : string;
    p_cPostal         : string;
    p_fNacimiento     : Date   | null;
    p_idGenero        : number | null;
    p_tarifaVidaId    : number | null;
    p_formaPagoId     : number | null;
    p_personas        : FamiliarModal[];
}
