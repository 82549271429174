import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RespuestaGenerica } from '../../generales/RespuestaGenerica';
import { ServiciosRutas } from '../../generales/ServiciosRutas';
import { ServiceGenerico } from '../../../services/service-generico.service';
import { ResponseCrudGenerico, ResponseGeneric } from '../../../model/generales/ResponseGeneric';
import { CatalogoPaquete, CatalogoPlanVida, 
         CoberturaVidaResponse, 
         CotizarVidaInsertarRequest, 
         CotizarVidaUpdateRequest, 
         PlanVidaEnum,  TarifasVidaPresentador,
         TarifasVidaResponse } from '../../../model/ramos/VidaModel';
import { Cpostal_response } from '../../../model/Catalogos/Cotizacion';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';

//#region Variables Generales JQUERY 'RFC'
  declare const RFCFiltraAcentos:    any;
  declare const RFCFiltraNombres:    any;
  declare const RFCApellidoCorto:    any;
  declare const RFCArmalo:           any;
  declare const RFCUnApellido:       any;
  declare const homonimia:           any;
  declare const RFCQuitaProhibidas:  any;
  declare const RFCDigitoVerificador:any;
//#endregion

@Component({
  selector: 'app-vida',
  templateUrl: './vida.component.html',
  styleUrls: ['./vida.component.css'],
  providers: [ServiceGenerico]
})
export class VidaComponent implements OnInit {
  @ViewChild("contentCotPDF", { static: false }) ModalCuponPDFH: TemplateRef<any>;
  modalCuponPDFH: NgbModalRef;
  
    //#region Propiedades publicas
    public tipoEtapa:       number = 1;
    public formulario:      FormGroup;
    public formularioFinal: FormGroup; 
    public planEsencial:    boolean = false;
    public planSalud:       boolean = false;
    public planAccidentes:  boolean = false;
    public planIntegral:    boolean = false;
    public puedeContinuar:  boolean = false;
    public planVidaEnumerado: PlanVidaEnum;
    public operacionRespuesta: RespuestaGenerica;
    //#endregion

    //#region Listas Publicas
    public lstPaquete: CatalogoPaquete[] = [];
    public lstPlan: CatalogoPlanVida[] = [];
    public lstTarifasDinamicas: TarifasVidaPresentador[] = [];
    public lstCoberturas: CoberturaVidaResponse[] = [];
    public listaColonias: Cpostal_response[] = [];
    //#endregion

    //#region Propiedades privadas
    private servicios: ServiciosRutas;
    //#endregion

    //#region Constructor Inicial
    constructor(private services: ServiceGenerico,
                private form: FormBuilder,
                private modalService: NgbModal,) {
      this.operacionRespuesta = new RespuestaGenerica();
      this.servicios = new ServiciosRutas();
      this.tipoEtapa = 1;
      this.InitiFormulario();
    }
    //#endregion

    //#region Métodos Eventos
    public async ngOnInit() {
      await this.CargarTipoPaquete();
      await this.CargarTipoPlan();
      await this.CargarCoberturasVida();
    }

    public limpiarFormularioEtapa(etapa: number): void {
      switch(etapa) {
        case 1 : 
        {
          this.formulario.reset();
          this.formulario.controls['p_PaqueteId'].setValue(1);
        }
        break;
      }
    }

    public async ObtenerTarifasVidas() {
      await this.ObtenerTiposTarifas();
    }

    public ObtenerDescripcion(tipoDescripcion: string): string {
       if (tipoDescripcion === 'p_plan') {
          const elemento = this.lstPaquete.find(f => f.idTipoRiesgoVida == this.formulario.get('p_PaqueteId').value);
          return elemento.nombre;
       } else {
          return this.formulario.get('p_IDGenero').value == 1 ? 'Hombre' : 'Mujer';
       }
    }

    public ObtenerDescripcionPlan(planId: number): string {
      let descripcion: string = '';
      const _planId = Number( planId );
      if (!isNaN(_planId)) {
        descripcion = _planId == PlanVidaEnum.Esencial ? 'Esencial' : _planId == PlanVidaEnum.Salud ? 'Salud'
                    : _planId == PlanVidaEnum.Accidentes ? 'Accidentes' : 'Integral';
      } 
      
      return descripcion;
    }

    public TarifaSeleccionada(planId: number, item: TarifasVidaPresentador ): void {
        this.puedeContinuar = false;
        this.planEsencial = false;
        this.planSalud = false;
        this.planAccidentes = false;
        this.planIntegral = false;
        this.formulario.controls['p_TipoPlanVidaId'].setValue(planId);
        this.formulario.controls['p_PagoQuincenal'].setValue(item.pagoQuincenal);
        this.lstTarifasDinamicas.forEach(element => {
            element.planEsencial = false;
            element.planSalud = false;
            element.planAccidentes = false;
            element.planIntegral = false;
            element.renglonSeleccinado = false;
        });
        this.lstCoberturas.forEach(element => {
            element.planEsencial = false;
            element.planSalud = false;
            element.planAccidentes = false;
            element.planIntegral = false;
        });
        item.renglonSeleccinado = true;
        switch(planId) {
              case PlanVidaEnum.Esencial:
                item.planEsencial = true;
                this.planEsencial = true;
                this.lstCoberturas.forEach(element => element.planEsencial = true);
                this.formulario.controls['p_sumaAsegurada'].setValue(item.pagoEsencial);
                this.puedeContinuar =  item.pagoEsencial > 0;
              break;
              case PlanVidaEnum.Salud:
                item.planSalud = true;
                this.planSalud = true;
                this.lstCoberturas.forEach(element => element.planSalud = true);
                this.formulario.controls['p_sumaAsegurada'].setValue(item.pagoSalud);
                this.puedeContinuar =  item.pagoSalud > 0;
              break;
              case PlanVidaEnum.Accidentes:
                item.planAccidentes = true;
                this.planAccidentes = true;
                this.lstCoberturas.forEach(element => element.planAccidentes = true );
                this.formulario.controls['p_sumaAsegurada'].setValue(item.pagoAccidentes);
                this.puedeContinuar =  item.pagoAccidentes > 0;
              break;
              case PlanVidaEnum.Integral:
                item.planIntegral = true;
                this.planIntegral = true;
                this.lstCoberturas.forEach(element => element.planIntegral = true);
                this.formulario.controls['p_sumaAsegurada'].setValue(item.pagoIntegral);
                this.puedeContinuar =  item.pagoIntegral > 0;
              break;
        }
        
        
    }
 
    public async ContinuarConProceso() {
        await this.EjecutarCotizacion();
    }

    public async guardarCliente() {
      await this.EjecutarDatosCliente();
    }

    public getRFC(): void {
      var ap_paterno = this.formularioFinal.value.p_APaterno;
      var ap_materno = this.formularioFinal.value.p_AMaterno;
      var nombre =     this.formularioFinal.value.p_Nombre;
      var rfc =        this.formularioFinal.value.p_FNacimiento;
      debugger
      rfc = (rfc.split('-')[0]).substring(2, 4) + rfc.split('-')[1] + rfc.split('-')[2];
      var sexo = this.formularioFinal.value.p_IDGenero;
      var estado = "VZ";  //falta validacion de estados solo es para curp
      if (sexo == 1) {
          sexo = 'H';
      } else {
          sexo = 'M';
      }
    
      var dteNacimiento = rfc;
      //FILTRA ACENTOS
      var ap_pat_f = RFCFiltraAcentos(ap_paterno.toLowerCase());
      var ap_mat_f = RFCFiltraAcentos(ap_materno.toLowerCase());
      var nombre_f = RFCFiltraAcentos(nombre.toLowerCase());
      //GUARDA NOMBRE ORIGINAL PARA GENERAR HOMOCLAVE
      var ap_pat_orig = ap_pat_f;
      var ap_mat_orig = ap_mat_f;
      var nombre_orig = nombre_f;
      //ELIMINA PALABRAS SOBRANTES DE LOS NOMBRES
      ap_pat_f = RFCFiltraNombres(ap_pat_f);
      ap_mat_f = RFCFiltraNombres(ap_mat_f);
      nombre_f = RFCFiltraNombres(nombre_f);
    
      if (ap_pat_f.length > 0 && ap_mat_f.length > 0) {
          if (ap_pat_f.length < 3) {
              rfc = RFCApellidoCorto(ap_pat_f, ap_mat_f, nombre_f);
          } else {
              rfc = RFCArmalo(ap_pat_f, ap_mat_f, nombre_f);
          }
      }
    
      if (ap_pat_f.length == 0 && ap_mat_f.length > 0) {
          rfc = RFCUnApellido(nombre_f, ap_mat_f);
      }
      if (ap_pat_f.length > 0 && ap_mat_f.length == 0) {
          rfc = RFCUnApellido(nombre_f, ap_pat_f);
      }
    
      rfc = RFCQuitaProhibidas(rfc);
    
      rfc = rfc.toUpperCase() + dteNacimiento + homonimia(ap_pat_orig, ap_mat_orig, nombre_orig);
    
      rfc = RFCDigitoVerificador(rfc);
    
      this.formularioFinal.value.p_RFC = rfc;
      this.formularioFinal.patchValue(this.formularioFinal.value);
    
    }
    //#endregion

    //#region Métodos privados
    private InitiFormulario(): void {

      this.formulario = this.form.group({
        p_numeroEmpleado: [""],
        p_claveAsesor: ["9088"],
        p_NombreProspecto: ["", Validators.required],
        p_Correo:          ["", Validators.required],
        p_Celular:         [""],
        p_CPostal:         ["", Validators.required],
        p_FNacimiento:     ["", Validators.required],
        p_IDGenero:        [0,  Validators.required],
        p_PaqueteId:       [0],
        p_TipoPlanVidaId:  [0],
        p_PagoQuincenal:   [0],
        p_sumaAsegurada:   [''],
        p_edad:            [0],
        p_id:              [0]
      });

      this.formularioFinal = this.form.group({
            p_id:              [0],
            p_Nombre:          ['', Validators.required],
            p_APaterno:        ['', Validators.required],
            p_AMaterno:        ['', Validators.required],
            p_RFC:             ['', Validators.required],
            p_IDGenero:        [0],
            p_FNacimiento:     ['', Validators.required],
            p_Correo:          ['', Validators.required],
            p_Telefono:        ['', Validators.required],
            p_Celular:         ['', Validators.required],
            p_Calle:           ['', Validators.required],
            p_Next:            [0,  Validators.required],
            p_Nint:            [0,  Validators.required],
            p_CPostal:         [0,  Validators.required],
            p_Colonia:         [0,  Validators.required],
            p_Municipio:       [0,  Validators.required],
            p_Estado:          [0,  Validators.required],
      });
    }

    public CalcularEdad(): number {
      if (this.formulario.get('p_FNacimiento').value) {
          var fechaNacimiento = new Date(this.formulario.get('p_FNacimiento').value);
          var tiempoDiferencia = Math.abs(Date.now() - fechaNacimiento.getTime());
          const edadActual = Math.ceil((tiempoDiferencia / (1000 * 3600 * 24)) / 365);
          this.formulario.controls['p_edad'].setValue(edadActual);
          return edadActual;
      } else {
          this.formulario.controls['p_edad'].setValue(0);
          return 0;
      }
    }

    private crearArgumentosTarifa(item: TarifasVidaResponse): void {
        this.puedeContinuar = false;
        this.lstTarifasDinamicas = [];
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(1,100,  item.esencial.costo100, item.salud.costo100, item.accidentes.costo100, item.integral.costo100));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(2,125,  item.esencial.costo125, item.salud.costo125, item.accidentes.costo125, item.integral.costo125));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(3,150,  item.esencial.costo150, item.salud.costo150, item.accidentes.costo150, item.integral.costo150));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(4,200,  item.esencial.costo200, item.salud.costo200, item.accidentes.costo200, item.integral.costo200));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(5,225,  item.esencial.costo225, item.salud.costo225, item.accidentes.costo225, item.integral.costo225));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(6,250,  item.esencial.costo250, item.salud.costo250, item.accidentes.costo250, item.integral.costo250));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(7,300,  item.esencial.costo300, item.salud.costo300, item.accidentes.costo300, item.integral.costo300));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(8,350,  item.esencial.costo350, item.salud.costo350, item.accidentes.costo350, item.integral.costo350));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(9,400,  item.esencial.costo400, item.salud.costo400, item.accidentes.costo400, item.integral.costo400));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(10,450, item.esencial.costo450, item.salud.costo450, item.accidentes.costo450, item.integral.costo450));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(11,500, item.esencial.costo500, item.salud.costo500, item.accidentes.costo500, item.integral.costo500));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(12,550, item.esencial.costo550, item.salud.costo550, item.accidentes.costo550, item.integral.costo550));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(13,600, item.esencial.costo600, item.salud.costo600, item.accidentes.costo600, item.integral.costo600));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(14,650, item.esencial.costo650, item.salud.costo650, item.accidentes.costo650, item.integral.costo650));
        this.lstTarifasDinamicas.push(new TarifasVidaPresentador(15,700, item.esencial.costo700, item.salud.costo700, item.accidentes.costo700, item.integral.costo700));
    }

    private ObtenerDatosInsertar(): CotizarVidaInsertarRequest {
        const parametros = new CotizarVidaInsertarRequest();
        parametros.p_nombreProspecto  = this.formulario.get('p_NombreProspecto').value;
        parametros.p_correo           = this.formulario.get('p_Correo').value;
        parametros.p_celular          = this.formulario.get('p_Celular').value;
        parametros.p_cPostal          = this.formulario.get('p_CPostal').value;
        parametros.p_fNacimiento      = new Date(this.formulario.get('p_FNacimiento').value);
        parametros.p_idGenero         = Number(this.formulario.get('p_IDGenero').value);
        parametros.p_paqueteId        = Number(this.formulario.get('p_PaqueteId').value);
        parametros.p_tipoPlanVidaId   = Number(this.formulario.get('p_TipoPlanVidaId').value);
        parametros.p_pagoQuincenal    = Number(this.formulario.get('p_PagoQuincenal').value);
        parametros.p_sumaAsegurada    = Number(this.formulario.get('p_sumaAsegurada').value);
        return parametros;
    }

    private ObtenerDatosActulizar(): CotizarVidaUpdateRequest {
      const parametros = new CotizarVidaUpdateRequest();
      parametros.p_nombre           = this.formularioFinal.get('p_Nombre').value;
      parametros.p_aPaterno         = this.formularioFinal.get('p_APaterno').value;
      parametros.p_aMaterno         = this.formularioFinal.get('p_AMaterno').value;
      parametros.p_rfc              = this.formularioFinal.get('p_RFC').value;
      parametros.p_Telefono         = this.formularioFinal.get('p_Telefono').value;
      parametros.p_celular          = this.formularioFinal.get('p_Celular').value;
      parametros.p_fNacimiento      = new Date( this.formularioFinal.get('p_FNacimiento').value );
      parametros.p_cPostal          = this.formularioFinal.get('p_CPostal').value;
      parametros.p_correo           = this.formularioFinal.get('p_Correo').value;
      parametros.p_id               = this.formularioFinal.get('p_id').value;
      return parametros;
    }
    //#endregion

    //#region Métodos servicios 
    private async CargarTipoPaquete() {
      this.lstPaquete = [];
      this.operacionRespuesta.EstaEjecutando = true;
      await this.services.GetAsync(`${this.servicios.serviciosCatalogos}/VidaCatalogo/ConsultarTipoRiesgo`)
          .then((response: ResponseGeneric<Array<CatalogoPaquete>>) => {
              this.operacionRespuesta.EstaEjecutando = false;
              this.lstPaquete = response.response.filter(f => f.estado === true);
              this.formulario.controls['p_PaqueteId'].setValue(1);
          }).catch((errorBackend) => {
              console.log(errorBackend);
          });
    }

    private async CargarTipoPlan() {
      this.lstPlan = [];
      this.operacionRespuesta.EstaEjecutando = true;
      await this.services.GetAsync(`${this.servicios.serviciosCatalogos}/PlanVida/ConsultarTipoPaquete`)
          .then((response: ResponseGeneric<Array<CatalogoPlanVida>>) => {
              this.operacionRespuesta.EstaEjecutando = false;
              this.lstPlan = response.response.filter(f => f.estado === true);
          }).catch((errorBackend) => {
              console.log(errorBackend);
          });
    }

    private async ObtenerCPostal(valor: string) {
      this.operacionRespuesta.EstaEjecutando = true;
      this.listaColonias = [];
      await this.services.GetAsync(`${this.servicios.serviciosOperaciones}/Cotizacion/GetListaColonias?p_CPostal=${valor}`)
          .then((response: ResponseGeneric<Array<Cpostal_response>>) => {
              this.operacionRespuesta.EstaEjecutando = false;
              this.listaColonias = response.response;
              this.formularioFinal.patchValue({
                  p_Municipio: this.listaColonias[0].municipio,
                  p_Estado:    this.listaColonias[0].estado
              });
          }).catch((errorBackend) => {
              this.operacionRespuesta.EstaEjecutando = false;
              console.log(errorBackend);
          });
    }

    private async CargarCoberturasVida() {
      this.lstCoberturas = [];
      this.operacionRespuesta.EstaEjecutando = true;
      await this.services.GetAsync(`${this.servicios.serviciosCatalogos}/CoberturaVidaCatalogo/Consultar`)
          .then((response: ResponseGeneric<Array<CoberturaVidaResponse>>) => {
              this.operacionRespuesta.EstaEjecutando = false;
              this.lstCoberturas = response.response;
          }).catch((errorBackend) => {
              console.log(errorBackend);
          });
    } 

    private async ObtenerTiposTarifas() {
      const p_edad = this.CalcularEdad();
      const p_tipo_riesgo = this.formulario.get('p_PaqueteId').value;
      this.operacionRespuesta.EstaEjecutando = true;
      await this.services.GetAsync(`${this.servicios.serviciosOperaciones}/TarifasVida/ConsultarTarifasVida?p_edad=${p_edad}&p_tipo_riesgo=${p_tipo_riesgo}`)
          .then((response: ResponseGeneric<TarifasVidaResponse>) => {
              this.operacionRespuesta.EstaEjecutando = false;
              this.crearArgumentosTarifa(response.response);
              this.tipoEtapa = 2;
          }).catch((errorBackend) => {
              console.log(errorBackend);
          });
    }

    private async EjecutarCotizacion() {
        this.operacionRespuesta.EstaEjecutando = true;
        const param = this.ObtenerDatosInsertar();
        await this.services.PostAsync(param,`${this.servicios.serviciosOperaciones}/VidaCotizacion/Post`)
          .then(async (response: ResponseGeneric<Array<ResponseCrudGenerico>>) => {
              this.operacionRespuesta.EstaEjecutando = false;  
              if ( response.response.length > 0) {
                   this.tipoEtapa = 3;
                   this.formulario.controls['p_id'].setValue(response.response[0].registro_id);
                   this.formularioFinal.patchValue({
                        p_id:           this.formulario.get('p_id').value,
                        p_IDGenero:     this.formulario.get('p_IDGenero').value,   
                        p_FNacimiento:  this.formulario.get('p_FNacimiento').value,   
                        p_Correo:       this.formulario.get('p_Correo').value,     
                        p_Celular:      this.formulario.get('p_Celular').value,  
                        p_CPostal:      this.formulario.get('p_CPostal').value,   
                   });
                   await this.ObtenerCPostal(this.formulario.get('p_CPostal').value);
              }
          }).catch((errorBackend) => {
              this.operacionRespuesta.EstaEjecutando = false;  
              console.log(errorBackend);
          });
    }

    private async EjecutarDatosCliente() {
      this.operacionRespuesta.EstaEjecutando = true;
      const param = this.ObtenerDatosActulizar();
      await this.services.PutAsync(param,`${this.servicios.serviciosOperaciones}/VidaCotizacion/Put`)
        .then(async (response: ResponseGeneric<Array<ResponseCrudGenerico>>) => {
            this.operacionRespuesta.EstaEjecutando = false;  
            if ( response.response.length > 0) {
                 this.tipoEtapa = 3;
                
            }
        }).catch((errorBackend) => {
            this.operacionRespuesta.EstaEjecutando = false;  
            console.log(errorBackend);
        });
  }

  imprimirPDF() {

    this.modalCuponPDFH = this.modalService.open(this.ModalCuponPDFH, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }  

  generarPDF() {
    const options = {
      filename: 'Cotizacion.pdf',
      image: { type: 'jpeg', quality: 1.0 }, // Establece una calidad de imagen máxima (rango: 0.0 - 1.0)
      html2canvas: { scale: 2 }, // Configuración de rendering de HTML2Canvas
      jsPDF: { orientation: 'portrait' }
    };
  
    const element = document.getElementById('htmlPrint');
  
    html2pdf()
      .set(options)
      .from(element)
      .save();
  }

  _imprimirPDF() {
    const modalContent = document.getElementById('htmlPrint');
    if (modalContent) {
      const ventanaImpresion = window.open('', '_blank');
      const estilos = Array.from(document.getElementsByTagName('link'));
      const estilosHTML = estilos.map((estilo) => estilo.outerHTML).join('');
  
      ventanaImpresion.document.write(`<html><head><title>Imprimir Cotización</title>${estilosHTML}</head><body><div class="row m-4"><div class="col-md-12"><div class="card px-2">`);
      ventanaImpresion.document.write(modalContent.innerHTML);
      ventanaImpresion.document.write('</div></div></div></body></html><script>window.print(); </script>');
  
    }
  }
  cerrarModalPDF() {
    this.modalCuponPDFH.close();
  }
    //#endregion

}

