
export class Respuesta_WS_response {
      idCotizacion: number;
      primaTotal: number;
      primaTotalPR: number;
      primaReciboS: number;
      numCotizacion?: string;
      nombrePaquete: string;
      nombreFormaPago: string;
      idAseguradora: number;
      totalIVA: number;
      totalPrimaNeta: number;
      nombreAseguradora: string;
      hasError: boolean;
      txtError: string;
}

export class Cpostal_response
{
    colonia: string;
    municipio: string;
    estado: string;
}

export class Lista_cotizaiones_response {
      idCotizacion: number;
      nombreProspecto: string;
      marca: string;
      modelo: number;
      descripcion: string;
      fechaRegistro: Date;
}

export class VersionANA_response {
      transaccionesVehiculo: {
            vehiculos: { clave: string; nombre: string }[];
            error: string;
          };
          amisSelected: string;
          status: number;
}

export class VersionGNP_response {
      transaccionesVehiculo: {
            clave: string;
            nombre: string;
            valor: string;
          }[];
          amisSelected: string;
          status: number;
}


export class Cotizacion_salud_response
{
      success: number;
      _IDCotizacion: number;
      primatotal: number;
      primaTotalSemanal: number;
      primatotalCatorcenal: number;
}