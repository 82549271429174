import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../guards/AuthGuard';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { LocalStorageService } from 'src/services/local-storage.service';
import { AuthIdentity } from '../guards/AuthIdentity';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { ServiciosRutas } from '../generales/ServiciosRutas';
import { RespuestaGenerica } from '../generales/RespuestaGenerica';
import { Lista_negocios_x_usuario_modelo_response } from 'src/model/Catalogos/Generales';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [AuthGuard, ServiceGenerico],
})
export class MenuComponent implements OnInit {

  public isAuth: boolean;
  public banner: string;
  opcionMenu: number = 0;
  usuarioActual: object;
  nombreUsuario: any;
  idUsuario: number;
  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  listaNegocios: Lista_negocios_x_usuario_modelo_response[];
  _idNegocio: number;
  private _bndCatalogo: boolean;
  idPerfil: number;
  constructor(
    private auth: AuthGuard,
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private services: ServiceGenerico,
    private menuService: MenuService
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.isAuth = this.auth.canActivate();
    if (this.isAuth === false) {
      this.cerrarSesion();
    } else {
      this.authService.estatusActualDelUsuarioEnSesion$().subscribe((isAuth) => {
        this.isAuth = isAuth;
        this.menuService.abrirMenu('0');
        this._bndCatalogo = false;
      });

      var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
      this.nombreUsuario = usuarioActual.Nombre + ' ' + usuarioActual.APaterno + ' ' + usuarioActual.AMaterno;
      this.idUsuario = usuarioActual.IDUsuario;
      this.idPerfil = usuarioActual.IDPerfil;
      this.verNegociosUsuario();
    }
  }

  // public abrirMenu(menu: string) {
  //   this.opcionMenu;
  //   if (menu.includes("listaRamos")) {
  //     this._idNegocio = parseInt(menu.split('_')[1]);
  //     menu = 'listaRamos';
  //   }

  //   switch (menu) {
  //     case 'usuarios':
  //       this.opcionMenu = 1;
  //       $('.font-weight-bold').removeClass('font-weight-bold');
  //       $('#' + menu).addClass('font-weight-bold');
  //       break;
  //     case 'listaRamos':
  //       this.opcionMenu = 2;
  //       $('.font-weight-bold').removeClass('font-weight-bold');
  //       $('#listaRamos_' + this._idNegocio).addClass('font-weight-bold');
  //       break;
  //     case 'catalogos':
  //       this.opcionMenu = 3;
  //       $('.font-weight-bold').removeClass('font-weight-bold');
  //       $('#' + menu).addClass('font-weight-bold');
  //       if (this._bndCatalogo == true) {
  //         this._bndCatalogo = false;
  //       } else {
  //         this._bndCatalogo = true;
  //       }
  //       break;
  //     case 'Autos':
  //       this.opcionMenu = 4;
  //       break;
  //     case 'listado':
  //       this.opcionMenu = 5;
  //       $('.font-weight-bold').removeClass('font-weight-bold');
  //       $('#' + menu).addClass('font-weight-bold');
  //       break;
  //     case 'Salud':
  //       this.opcionMenu = 6;
  //       break;
  //     case 'Vida':
  //       this.opcionMenu = 7;
  //       break;
  //     // case 'catCategorias':
  //     //   this.opcionMenu = 6;
  //     //   break;
  //     // case 'catClientes':
  //     //   this.opcionMenu = 7;
  //     //   break;
  //     // case 'catGrupos':
  //     //   this.opcionMenu = 8;
  //     //   break;
  //     // case 'capTarifa':
  //     //   this.opcionMenu = 9;
  //     //   break;
  //     // case 'AutTarifaHosp':
  //     //   this.opcionMenu = 10;
  //     //   break;
  //     // case 'AutTarifaTrans':
  //     //   this.opcionMenu = 11;
  //     //   break;
  //     // case 'GenTarifarioHosp':
  //     //   this.opcionMenu = 12;
  //     //   break;
  //     // case 'GenTarifarioTrans':
  //     //   this.opcionMenu = 13;
  //     //   break;
  //     default:
  //       this.opcionMenu = 0; // Opcional: asignar un valor por defecto si no coincide con ninguna opción.
  //       break;
  //   }
  // }

  abrirMenu(menu: string) {
    this.opcionMenu = this.menuService.abrirMenu(menu); 

    if (menu.includes("listaRamos")) {
      this._idNegocio = parseInt(menu.split('_')[1]);
      menu = 'listaRamos';
      $('.font-weight-bold').removeClass('font-weight-bold');
      $('#listaRamos_' + this._idNegocio).addClass('font-weight-bold');
    }
  }

  verNegociosUsuario() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/GetNegocios_x_Usuario?p_IDUsuario=" + this.idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaNegocios = tempdate.response;

          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

  }

  public cerrarSesion(): void {
    this.auth.logOut();
    this.authService.EsEstaAutenticado(false);
    this.localStorageService.clearToken();

    this.router.navigate(["/login"]);
  }
}
