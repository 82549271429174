import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthGuard } from '../../../app/guards/AuthGuard';


@Component({
  selector: 'app-campo-mensajes-validaciones',
  templateUrl: './campo-mensajes-validaciones.component.html',
  styleUrls: ['./campo-mensajes-validaciones.component.css']
})
export class CampoMensajesValidacionesComponent implements OnInit {

  @Input() control: FormControl;

  ngOnInit(): void { }
}
