import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RespuestaGenerica } from '../../generales/RespuestaGenerica';
import { ServiciosRutas } from '../../generales/ServiciosRutas';
import { ServiceGenerico } from '../../../services/service-generico.service';
import { ResponseCrudGenerico, ResponseGeneric } from '../../../model/generales/ResponseGeneric';
import { FamiliarModal,       FormaDepago, PlanFamiliarRegistros, 
        SaludInsertarRequest, TarifaSalud, TarifasVidaResponse 
} from '../../../model/ramos/SaludModel';
import { Cpostal_response } from '../../../model/Catalogos/Cotizacion';

//#region Variables Generales JQUERY 'RFC'
declare const RFCFiltraAcentos:    any;
declare const RFCFiltraNombres:    any;
declare const RFCApellidoCorto:    any;
declare const RFCArmalo:           any;
declare const RFCUnApellido:       any;
declare const homonimia:           any;
declare const RFCQuitaProhibidas:  any;
declare const RFCDigitoVerificador:any;
//#endregion

@Component({
  selector: 'app-salud',
  templateUrl: './salud.component.html',
  styleUrls: ['./salud.component.css'],
  providers: [ServiceGenerico]
})
export class SaludComponent implements OnInit {

  //#region Propiedades publicas
  public tipoEtapa:       number = 1;
  public formulario:      FormGroup;
  public formularioFinal: FormGroup;
  public operacionRespuesta:   RespuestaGenerica;
  public registrosFamilias:    PlanFamiliarRegistros;
  //#endregion

  //#region Listas Publicas
  public lstTarifas:      TarifaSalud[] = [];
  public lstFormasDepago: FormaDepago[] = [];
  public listaColonias:   Cpostal_response[] = [];
 //#endregion

  //#region Propiedades privadas
  private itemValorMaximo: number = 0;
  private tarifaActual: TarifasVidaResponse = null;
  private servicios: ServiciosRutas;
  //#endregion

  //#region Constructor Inicial
   constructor(private services: ServiceGenerico,
              private form: FormBuilder) {
          this.operacionRespuesta = new RespuestaGenerica();
          this.servicios = new ServiciosRutas();
          this.InitValoresComponente();
          this.InitiFormulario();
   }
   //#endregion
 
  //#region Métodos Eventos
  public async ngOnInit() {
    await this.CargarTipoTarifa();
    await this.CargarFormasDePago();
  }

  public CalcularEdad(): number {
    if (this.formulario.get('p_FNacimiento').value) {
        var fechaNacimiento = new Date(this.formulario.get('p_FNacimiento').value); 
        const edadActual = this.CalculaEdadPersona(fechaNacimiento);
        this.formulario.controls['p_edad'].setValue(edadActual);
        return edadActual;
    } else {
        this.formulario.controls['p_edad'].setValue(0);
        return 0;
    }
  }

  public RenderizarFormulariosFamilias(valor: any): void {
     this.itemValorMaximo = 0;
     const valorActual = Number(valor);
     if ( !isNaN(valorActual) ) {
          this.itemValorMaximo = (valorActual - 1);
          this.formulario.setControl('p_argFamilia', this.form.array([]));
          this.registrosFamilias = new PlanFamiliarRegistros( this.itemValorMaximo );
     }
  }

  public ObtenerDescripcionPlan(paqueteId: number) {
    if (paqueteId) {
       const elemento = this.lstTarifas.find(f => f.tarifa_saludId == paqueteId);
       return elemento.nombre;
    } else {
      return "N/A";
    }
  }

  public ObtenerDescripcionFormaDePago(formaId: number) {
    if (formaId) {
      const elemento = this.lstFormasDepago.find(f => f.formaPagoId == formaId);
      return elemento.nombre;
   } else {
     return "N/A";
   }
  }

  public AsignarGenero(valor: any, item: FamiliarModal): void {
      if (valor !== null || valor !== undefined) {
          this.AsignarValoresControles(false, valor, item);
      }
  }

  public AsignarFechaNacimiento(valor: any, item: FamiliarModal): void {
      if ( valor !== null || valor !== undefined) {
           this.AsignarValoresControles(true, valor, item);
      }
  }
  //#endregion
 
  //#region Métodos privados
  private InitiFormulario(): void {
      this.formulario = this.form.group({
        p_NumeroEmpleado :   ["", Validators.required],
        p_NombreProspecto:   ["", Validators.required],
        p_Correo:            ["", Validators.required],
        p_Celular:           [""],
        p_CPostal:           ["", Validators.required],
        p_FNacimiento:       ["", Validators.required],
        p_IDGenero:          [0,  Validators.required],
        p_edad:              [0],
        p_PaqueteId:         [null],
        p_formaDePago:       [null],
        p_cantidaFamiliares: [null],
        p_primaTotal:        [null],
        p_primaPr:           [null],
        p_argFamilia:        this.form.array([])
      });

      this.formularioFinal = this.form.group({
        p_id:              [0],
        p_Nombre:          ['', Validators.required],
        p_APaterno:        ['', Validators.required],
        p_AMaterno:        ['', Validators.required],
        p_RFC:             ['', Validators.required],
        p_IDGenero:        [0],
        p_FNacimiento:     ['', Validators.required],
        p_Correo:          ['', Validators.required],
        p_Telefono:        ['', Validators.required],
        p_Celular:         ['', Validators.required],
        p_Calle:           ['', Validators.required],
        p_Next:            [0,  Validators.required],
        p_Nint:            [0,  Validators.required],
        p_CPostal:         [0,  Validators.required],
        p_Colonia:         [0,  Validators.required],
        p_Municipio:       [0,  Validators.required],
        p_Estado:          [0,  Validators.required],
      });
  }

  private InitValoresComponente(): void {
      this.tipoEtapa = 1;
      this.itemValorMaximo = 0;
  }

  private AsignarValoresControles(esFecha: boolean, valor: any, item: FamiliarModal ): void {
    const persona = this.registrosFamilias.lstPersonas.find(elemento => elemento.p_id === item.p_id);
    if (esFecha && persona != null) {
        const fechaPersona = new Date(valor);
        persona.p_fechaNacimiento = null;
        persona.p_fechaNacimiento = fechaPersona;
        persona.p_edad = this.CalculaEdadPersona(fechaPersona);
    } else {
      persona.p_generoId = 0;
      persona.p_generoId = Number(valor);
    }
  }

  private CalculaEdadPersona(fecha: Date): number {
    var tiempoDiferencia = Math.abs(Date.now() - fecha.getTime());
    const  edadActual    = Math.ceil((tiempoDiferencia / (1000 * 3600 * 24)) / 365);
    return edadActual;
  }

  private ObtenerParametrosSalud(): SaludInsertarRequest {
      const parametros = new SaludInsertarRequest();
      parametros.p_numeroEmpleado   = this.formulario.get('p_NumeroEmpleado').value;
      parametros.p_nombreProspecto  = this.formulario.get('p_NombreProspecto').value;
      parametros.p_correo           = this.formulario.get('p_Correo').value;
      parametros.p_celular          = this.formulario.get('p_Celular').value;
      parametros.p_cPostal          = this.formulario.get('p_CPostal').value;
      parametros.p_fNacimiento      = new Date(this.formulario.get('p_FNacimiento').value);
      parametros.p_idGenero         = Number(this.formulario.get('p_IDGenero').value);
      parametros.p_tarifaVidaId     = Number(this.formulario.get('p_PaqueteId').value);
      parametros.p_formaPagoId      = Number(this.formulario.get('p_formaDePago').value);
      parametros.p_personas         = this.registrosFamilias.lstPersonas;
      return parametros;
  }

  public getRFC(): void {
    var ap_paterno = this.formularioFinal.value.p_APaterno;
    var ap_materno = this.formularioFinal.value.p_AMaterno;
    var nombre =     this.formularioFinal.value.p_Nombre;
    var rfc =        this.formularioFinal.value.p_FNacimiento;
    rfc = (rfc.split('-')[0]).substring(2, 4) + rfc.split('-')[1] + rfc.split('-')[2];
    var sexo = this.formularioFinal.value.p_IDGenero;
    var estado = "VZ";  //falta validacion de estados solo es para curp
    if (sexo == 1) {
        sexo = 'H';
    } else {
        sexo = 'M';
    }
  
    var dteNacimiento = rfc;
    //FILTRA ACENTOS
    var ap_pat_f = RFCFiltraAcentos(ap_paterno.toLowerCase());
    var ap_mat_f = RFCFiltraAcentos(ap_materno.toLowerCase());
    var nombre_f = RFCFiltraAcentos(nombre.toLowerCase());
    //GUARDA NOMBRE ORIGINAL PARA GENERAR HOMOCLAVE
    var ap_pat_orig = ap_pat_f;
    var ap_mat_orig = ap_mat_f;
    var nombre_orig = nombre_f;
    //ELIMINA PALABRAS SOBRANTES DE LOS NOMBRES
    ap_pat_f = RFCFiltraNombres(ap_pat_f);
    ap_mat_f = RFCFiltraNombres(ap_mat_f);
    nombre_f = RFCFiltraNombres(nombre_f);
  
    if (ap_pat_f.length > 0 && ap_mat_f.length > 0) {
        if (ap_pat_f.length < 3) {
            rfc = RFCApellidoCorto(ap_pat_f, ap_mat_f, nombre_f);
        } else {
            rfc = RFCArmalo(ap_pat_f, ap_mat_f, nombre_f);
        }
    }
  
    if (ap_pat_f.length == 0 && ap_mat_f.length > 0) {
        rfc = RFCUnApellido(nombre_f, ap_mat_f);
    }
    if (ap_pat_f.length > 0 && ap_mat_f.length == 0) {
        rfc = RFCUnApellido(nombre_f, ap_pat_f);
    }
  
    rfc = RFCQuitaProhibidas(rfc);
  
    rfc = rfc.toUpperCase() + dteNacimiento + homonimia(ap_pat_orig, ap_mat_orig, nombre_orig);
  
    rfc = RFCDigitoVerificador(rfc);
  
    this.formularioFinal.value.p_RFC = rfc;
    this.formularioFinal.patchValue(this.formularioFinal.value);
  
  }
  //#endregion

  //#region Métodos servicios 
   private async CargarTipoTarifa() {
    this.lstTarifas = [];
    this.operacionRespuesta.EstaEjecutando = true;
    await this.services.GetAsync(`${this.servicios.serviciosCatalogos}/SaludCatalogo/ConsultarTipoTarifa`)
        .then((response: ResponseGeneric<Array<TarifaSalud>>) => {
            this.operacionRespuesta.EstaEjecutando = false;
            this.lstTarifas = response.response.filter(f => f.estado === true);
        }).catch((errorBackend) => {
            console.log(errorBackend);
        });
   }

   private async CargarFormasDePago() {
    this.lstFormasDepago = [];
    this.operacionRespuesta.EstaEjecutando = true;
    await this.services.GetAsync(`${this.servicios.serviciosCatalogos}/FormaDePagoCatalogo/Consultar`)
        .then((response: ResponseGeneric<Array<FormaDepago>>) => {
            this.operacionRespuesta.EstaEjecutando = false;
            this.lstFormasDepago = response.response.filter(f => f.estado === true);
        }).catch((errorBackend) => {
            console.log(errorBackend);
        });
   }

   private async EjecutarCotizacion() {
        this.operacionRespuesta.EstaEjecutando = true;
        const param = this.ObtenerParametrosSalud();
        await this.services.PostAsync(param,`${this.servicios.serviciosOperaciones}/SaludCotizacion/Post`)
          .then(async (response: ResponseGeneric<Array<ResponseCrudGenerico>>) => {
              this.operacionRespuesta.EstaEjecutando = false;  
              if ( response.response.length > 0) {
                  this.tipoEtapa = 2;
                  this.formulario.controls['p_id'].setValue(response.response[0].registro_id);
                  this.formularioFinal.patchValue({
                        p_id:           this.formulario.get('p_id').value,
                        p_IDGenero:     this.formulario.get('p_IDGenero').value,   
                        p_FNacimiento:  this.formulario.get('p_FNacimiento').value,   
                        p_Correo:       this.formulario.get('p_Correo').value,     
                        p_Celular:      this.formulario.get('p_Celular').value,  
                        p_CPostal:      this.formulario.get('p_CPostal').value,   
                  });
                  await this.ObtenerCPostal(this.formulario.get('p_CPostal').value);
              }
          }).catch((errorBackend) => {
              this.operacionRespuesta.EstaEjecutando = false;  
              console.log(errorBackend);
          });
    }

    private async ObtenerCPostal(valor: string) {
      this.operacionRespuesta.EstaEjecutando = true;
      this.listaColonias = [];
      await this.services.GetAsync(`${this.servicios.serviciosOperaciones}/Cotizacion/GetListaColonias?p_CPostal=${valor}`)
          .then((response: ResponseGeneric<Array<Cpostal_response>>) => {
              this.operacionRespuesta.EstaEjecutando = false;
              this.listaColonias = response.response;
              this.formularioFinal.patchValue({
                  p_Municipio: this.listaColonias[0].municipio,
                  p_Estado:    this.listaColonias[0].estado
              });
          }).catch((errorBackend) => {
              this.operacionRespuesta.EstaEjecutando = false;
              console.log(errorBackend);
          });
    }

   private async ObtenerTarifaSaludServicio(itemTarifa: any) {
    this.tarifaActual = null;
    const p_edad = this.CalcularEdad();
    const p_genero = this.formulario.get('p_IDGenero').value;
    this.operacionRespuesta.EstaEjecutando = true;
    await this.services.GetAsync(`${this.servicios.serviciosOperaciones}/TarifasSalud/ConsultarTarifasSalud?p_tipoTarifaId=${itemTarifa}&p_edad=${p_edad}&p_genero=${p_genero}`)
        .then((response: ResponseGeneric<Array<TarifasVidaResponse>>) => {
            this.operacionRespuesta.EstaEjecutando = false;
            this.tarifaActual = response.response[0];
        }).catch((errorBackend) => {
            this.tarifaActual = null;
            console.log(errorBackend);
        });
   }
   //#endregion
}
