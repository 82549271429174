import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { Lista_cotizaiones_response } from 'src/model/Catalogos/Cotizacion';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-lista-polizas',
  templateUrl: './lista-polizas.component.html',
  styleUrls: ['./lista-polizas.component.css'],
  providers: [ServiceGenerico]
})
export class ListaPolizasComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  private modelo_configuracion: ServiciosRutas;
  // listaUsuarios: Lista_usuarios_sistema_modelo_response[];
  public operacionRespuesta: RespuestaGenerica;
  listaCotizaciones: Lista_cotizaiones_response[];
  opcion: number = 0;

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerCotizaciones();
  }
  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerCotizaciones() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Cotizacion/GetListaCotizaciones?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCotizaciones = [] = tempdate.response as Lista_cotizaiones_response[];
            this.renderTabla();
          } else {
            this.listaCotizaciones = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  verDetalleCotizacion(idCotizacion) {
    this.opcion = 1; 
  }
}
