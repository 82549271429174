import { Time } from "@angular/common";
import { Timestamp } from "rxjs/internal/operators/timestamp";

export class Lista_cpostal_modelo_response {
    cat_cpostal_id: number;
    cat_municipio_id: number; 
    cat_estado_id: number; 
    cpostal: number; 
    nombre_colonia: string; 
    nombre_municipio: string; 
    nombre_estado: string; 
}

export class Lista_municipio_modelo_response {
    cat_municipio_id: number; 
    cat_estado_id: number; 
    nombre_municipio: string; 
}

export class Lista_estado_modelo_response {
    cat_estado_id: number; 
    nombre_estado: string; 
}

export class Lista_perfiles_modelo_response {
    IDPerfil: number; 
    Nombre: string; 
    Descripcion: string; 
}

export class Lista_negocios_x_usuario_modelo_response {
    IDUsuario: number; 
    IDNegocio: number; 
    Nombre: string; 
    Descripcion: string; 
}
export class Lista_ramos_x_negocio_modelo_response {
    IDNegocio: number; 
    IDRamo: number; 
    Nombre: string; 
    Descripcion: string; 
}

export class Lista_negocios_modelo_response {
    IDNegocio: number; 
    Nombre: string; 
    Descripcion: string; 
}

export class Lista_aseguradoras_modelo_response {
    idAseguradora: number; 
    nombre: string; 
}

export class Info_perfil_modelo_response {
    idUsuario: number; 
    idContacto: number; 
    perfil: string; 
    usuario: string; 
    telefono: string; 
    negocios: string; 
    ramos: string; 
}