import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { MenuService } from '../menu.service';
import { ServiciosRutas } from '../generales/ServiciosRutas';
import { RespuestaGenerica } from '../generales/RespuestaGenerica';
import { AuthIdentity } from '../guards/AuthIdentity';
import { Info_perfil_modelo_response } from 'src/model/Catalogos/Generales';
import { ModuloModalMensajeComponent } from '../shared/modulo-modal-mensaje/modulo-modal-mensaje.component';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
  providers: [ServiceGenerico]
})
export class PerfilComponent implements OnInit {
  modelo_configuracion: ServiciosRutas;
  operacionRespuesta: RespuestaGenerica;
  nombreUsuario: string = "";
  claveAgente: string;
  idUsuario: any;
  infoPerfil: Info_perfil_modelo_response[];
  formGroupAgregarUsuario: FormGroup;
  mostrarContrasena: boolean = false;
  modalrefMsg: NgbModalRef;

  constructor(
    private services: ServiceGenerico,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private menuService: MenuService
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();

    var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
    this.nombreUsuario = usuarioActual.Nombre + ' ' + usuarioActual.APaterno + ' ' + usuarioActual.AMaterno;
    this.claveAgente = usuarioActual.Clave_asesor;
    this.idUsuario = usuarioActual.IDUsuario;
  }


  ngOnInit() {
  this.inicializarFormAgregarUsuario();
    this.getInfoUsuario(this.idUsuario);
  }


  getInfoUsuario(_idUsuario) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetInfoPerfilUsuario?p_IDUsuario=" + _idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.infoPerfil = tempdate.response as Info_perfil_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarUsuario() {
    this.formGroupAgregarUsuario = this.fb.group({
      p_IDUsuario: [this.idUsuario],
      p_Contrasena: ["", [Validators.required,
        Validators.minLength(8), // Cambia el número según tus requisitos
        Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]+$/) // Al menos una letra y un número
]]
    });
  }

  toggleMostrarContrasena() {
    this.mostrarContrasena = !this.mostrarContrasena;
  }

  actualizarContrasena() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarUsuario.value;

  this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/PutContrasena")
    .subscribe(
      (tempdate) => {
        if (tempdate.response[0].success == 1) {
          this.openMensajes(tempdate.response[0].msg, false, '');
          this.formGroupAgregarUsuario.get('p_Contrasena').reset();
        } else {
          this.openMensajes(tempdate.response[0].msg, true, '');
        }
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
  }

  
  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Usuarios';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
}
